import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CmsServayComponent } from './cms-servay/cms-servay.component';
import { ChangePasswordComponent } from './layout/ASAS-module/setting/change-password/change-password.component';
import { AntiBackGuardService, AuthGuard } from './shared';
import { SignUPComponent } from './layout/ASAS-module/Regstiration/sign-up/sign-up.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'Auth',
        pathMatch: 'full',
        redirectTo: 'login/Auth'
    },
    {
        path: '',
        loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule),
        canActivate: [AuthGuard]
    },

    { path: 'login', loadChildren: () => import('./login/login.module').then((m) => m.LoginModule) },
    { path: 'ASAS', loadChildren: () => import('./out-pages/out-pages.module').then((m) => m.OutPagesModule) },
    {
        path: 'survey/:email/:caseno',
        loadChildren: () => import('./cms-servay/cms-servay.module').then((m) => m.CmsServayModule)
    },
    {
        path: 'communsubscribe',
        loadChildren: () => import('./communication-unsubscribe/communication-unsubscribe.module').then((m) => m.CommunicationUnsubscribeModule)
    },
    
    {
        path: 'customcommunsubscribe',
        loadChildren: () => import('./custom-communication-unsubscribe/custom-communication-unsubscribe.module').then((m) => m.CustomCommunicationUnsubscribeModule)
    },
    
    // { path: 'quest', loadChildren: () => import('./cms-servay/cms-servay.module').then((m) => m.CmsServayModule) },
    { path: 'signUp', loadChildren: () => import('./signup/signup.module').then((m) => m.SignupModule) },

    {
        path: 'error',
        loadChildren: () => import('./server-error/server-error.module').then((m) => m.ServerErrorModule)
    },
    {
        path: 'reges',
        component: SignUPComponent,
        
    },
    {
        path: 'access-denied',
        loadChildren: () => import('./access-denied/access-denied.module').then((m) => m.AccessDeniedModule)
    },
    {
        path: 'vin-decoding',
        loadChildren: () => import('./vin-decoding/vin-decoding.module').then((m) => m.VinDecodingModule)
    },
    //{ path: 'vin', loadChildren: () => import('./vin-decoding/vin-decoding.module').then((m) => m.EtlNotificationModule) },
    { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule) },
    { path: '**', redirectTo: 'not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
