import { LocationStrategy, HashLocationStrategy, DatePipe, CommonModule } from '@angular/common';
import { ErrorService, JwtInterceptor } from './shared';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { MAT_DATE_LOCALE } from '@angular/material';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard, BaseService, SecurityService,SheetService,PresetService, MessageService,PresetManagerService,GeneralConfigurationService, FCRService, CMSService } from './shared';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';
import errorService from './shared/helpers/error';
import { FormBuilder } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    imports: [
        CommonModule, SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        jqxGridModule ,
        HttpClientModule, NgIdleKeepaliveModule.forRoot(),
        LanguageTranslationModule,
        jqxButtonModule,
        AppRoutingModule,
    ],
    declarations: [AppComponent],
    providers: [AuthGuard, BaseService, SecurityService,SheetService,PresetService, MessageService,PresetManagerService, 
        DatePipe,GeneralConfigurationService, FCRService, CMSService,ErrorService ,FormBuilder,
       // {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
    bootstrap: [AppComponent]
})
export class AppModule {}
