import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Result } from '../_models';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ErrorService {
    private canonicalName : string;
    private messageSource = new BehaviorSubject({});
    currentMessage = this.messageSource.asObservable();
    private actionFn = new BehaviorSubject('');
    currentAction = this.actionFn.asObservable();
    constructor(private http: HttpClient) {
        if (localStorage.getItem("canonicalName") !== null) {
            this.canonicalName = localStorage.getItem('canonicalName') + '/';
        } else {
            this.canonicalName = "";
        }

    }

    errorNumber='0'
    errorMsg=''
    

    getDBMassage(id) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Message/get-db-message/` + id);
    }

    getAPMassage(id: string) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Message/` + id);
    }

   

    
    

   



    //   this.authService.watchStorage().subscribe((data:string) => {
    //     this.CompaignDataLocal =  localStorage.CampaignData ? JSON.parse(localStorage.CampaignData) : {} ;    
    //     })
  
    //     this.authService.setItem('DrawingId',this.route.snapshot.params['id']);






}