export class campaign
{
    campaignNo : string;
    title : string;
    type: string;
    creationDate : Date;
    startDate : Date
    endDate : Date;
    // scope : 
    status : string
}