import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angular2-qrcode';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DemoMaterialModule } from './matrialModule';
import { OrderModule } from 'ngx-order-pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MessageSystemComponent } from './component/message-system/message-system.component';
import { CustomSearchComponent } from './component/custom-search/custom-search.component';
import { WizardStepsComponent } from './component/wizard-steps';
import { AutofocusDirective } from './_directives/autofocus.directive';
import { FCRComponent } from '../layout/ASAS-module/fcr/fcr.component';
import { CountsComponent } from '../layout/ASAS-module/counts/counts.component';

import { SearchPipe } from './pipes/search.pipe';
import { FileUploadModule } from 'ng2-file-upload';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PopoverModule } from 'ngx-smart-popover';
import { SelectEmpoyeeComponent } from './component/select-empoyee/select-empoyee.component';
import { SelectUserPopupComponent } from './component/select-user-popup/select-user-popup.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { JqxModule } from './jqwModels';
import { JQWidgetModule } from './jqwidgetModels';
import { AutosizeMe } from './_directives';

import { AttachmentGeneralNewComponent } from './component/attachment-general-new/attachment-general-new.component';
import { UploadImgB64Component } from './component/upload-img-b64/upload-img-b64.component';
import { AttachmentUDNComponent } from './component/attachment-udn/attachment-udn.component';
import { ReadMoreComponent } from './component/read-more/read-more.component';
import { LaddaModule } from 'angular2-ladda';
import { NgxEditorModule } from 'ngx-editor';
import { CustomeDatePipe } from './pipes/convertDateInString.pipe';
import { MatCardModule } from '@angular/material/card';
import { StylePaginatorDirective } from './_directives/stylePaging.directive';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        CommonModule,
        NgxMaterialTimepickerModule,
        PopoverModule,
        JQWidgetModule,
        JqxModule,
        NgxDaterangepickerMd.forRoot(),
        TranslateModule,
        FormsModule,
        DemoMaterialModule,
        ReactiveFormsModule,
        FileUploadModule,
        QRCodeModule,
        NgxSpinnerModule,
        BsDropdownModule.forRoot(),
        OrderModule,
        NgSelectModule,
        CKEditorModule,
        ModalModule.forRoot(),
        PerfectScrollbarModule,
        TabsModule.forRoot(),
        ProgressbarModule.forRoot(),
        NgxDatatableModule,
        SimpleNotificationsModule.forRoot(),
        LaddaModule,
        AlertModule.forRoot(),
        NgxEditorModule,
        MatCardModule
    ],

    exports: [
        FormsModule,
        AutofocusDirective,
        NgxMaterialTimepickerModule,
        AutosizeMe,
        UploadImgB64Component,
        ProgressbarModule,
        TabsModule,
        JqxModule,
        JQWidgetModule,
        NgxDaterangepickerMd,
        SimpleNotificationsModule,
        SelectEmpoyeeComponent,
        PerfectScrollbarModule,
        SelectUserPopupComponent,
        QRCodeModule,
        NgxSpinnerModule,
        BsDropdownModule,
        NgSelectModule,
        FileUploadModule,
        DemoMaterialModule,
        OrderModule,
        PopoverModule,
        LaddaModule,
        ReactiveFormsModule,
        CustomSearchComponent,
        SearchPipe,
        CKEditorModule,
        AttachmentGeneralNewComponent,
        ReadMoreComponent,
        MessageSystemComponent,
        AttachmentUDNComponent,
        ModalModule,
        AlertModule,
        StylePaginatorDirective,
        WizardStepsComponent,
        NgxDatatableModule,
        NgxEditorModule,
        CustomeDatePipe,
        FCRComponent,
        CountsComponent,
        MatCardModule
    ],

    declarations: [
        AutofocusDirective,
        AttachmentUDNComponent,
        ReadMoreComponent,
        MessageSystemComponent,
        StylePaginatorDirective,
        AutosizeMe,
        WizardStepsComponent,
        FCRComponent,
        CountsComponent,
        CustomSearchComponent,
        SearchPipe,
        SelectEmpoyeeComponent,
        SelectUserPopupComponent,
        AttachmentGeneralNewComponent,
        UploadImgB64Component,
        CustomeDatePipe
    ]
})
export class SharedModule {}
