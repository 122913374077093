import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError, finalize, first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseService } from '../services';
import { LoaderService } from '@shared/services/loader.service';
// import { MatSnackBar } from '@angular/material';
// import { SnackbarComponent } from '../containers/default-layout/snackbar/snackbar/snackbar.component';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    alerts: any[] = [
        {
            type: '',
            msg: ``,
            timeout: 1
        }
    ];
    dangerAlert = false;
    count = 0;
    constructor(private spinner: LoaderService, private _baseService: BaseService) {}
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<
        HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any
    > {
        if (this.shouldShowSpinner(request.url)) this.spinner.show();

        // setTimeout(() => this.spinner.hide(), 5000);
        this.count++;
        // add authorization header with jwt token if available
        let securityObject = JSON.parse(localStorage.getItem('securityObject'));
        if (securityObject && securityObject.bearerToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${securityObject.bearerToken}`
                }
            });
        }

        return next.handle(request).pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.url.includes('assets/i18n/en.json')) {
                        this.spinner.hide();
                        return;
                    }
                    if (err.status === 401) {
                         ;
                        window.location.href = '#/login';
                        this.count = 0;
                        this.spinner.hide();
                        return throwError(err);
                    } else {
                        this.spinner.hide();
                        return throwError(err);
                        // this._baseService.changeMessage({ id: '116', action: '' });
                    }
                }
            }),
            tap(
                (event) => {},

                (error) => {}
            ),
            finalize(() => {
                // if(this.count>0){

                // }
                // this.count--;
                // this.spinner.hide ('hem')
                // if ( this.count == 0 )
                this.spinner.hide();
            })
        );
    }

    // openSnackBar(message: string, action: string) {
    //   action='Close'
    //  let snackBar= this._snackBar.openFromComponent(SnackbarComponent,  {
    //   data:message,
    //   duration: 7000,
    //   verticalPosition: 'top',
    //   panelClass: 'pizza-party'
    //   });
    //   snackBar.afterDismissed().subscribe(() => {
    //   });

    //   snackBar.onAction().subscribe(() => {
    //     this._snackBar.dismiss()
    //   });
    // }

    shouldShowSpinner(url) {
        if (
            url.includes('PresetManager/exclude') ||
            url.includes('PresetManager/save-sheet') ||
            url.includes('is-sheet-saved-to-database') ||
            url.includes('PresetManager/update-row') ||
            url.includes('PresetManager/Is-Sftp-Profile-Connected')
        )
            return false;

        return true;
    }
}
