import * as JSZip from "jszip";
import { saveAs } from 'file-saver';
import { BaseService } from "./BaseService";
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class DownloadFileService{
    
    constructor(private _BaseService: BaseService ,private httpClient: HttpClient,  
        ) {}

    
    async getFile(url: string) {  
        const httpOptions = {  
          responseType: 'blob' as 'json'  
        };  
        const res = await this.httpClient.get(url, httpOptions).toPromise().catch((err: HttpErrorResponse) => {  
          const error = err.error;  
          return error;  
        });  
        return res;  
      }  

      async createZip(files: any[], controller: string) {  
        const zip = new JSZip();  
        const name = controller + '.zip';  
        for (let counter = 0; counter < files.length; counter++) {  
          const element = files[counter];  
          let str= this._BaseService.downloadFilebyid(controller,null, element)

          const fileData: any = await this.getFile(str); 
          if(fileData){
            const b: any = new Blob([fileData], { type: '' + fileData.type + '' });  
            zip.file(element.substring(element.lastIndexOf('/') + 1), b);  
          } 
        }  
        zip.generateAsync({ type: 'blob' }).then((content) => {  
          if (content) {  
            saveAs(content, name+'.zip');  
          }  
        });  
      }

      downloadFile(controller,files) {  
        this.createZip(files.map(c => c.file), controller);  
      }

}