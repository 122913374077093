import { jqxLayoutModule } from 'jqwidgets-ng/jqxlayout';
import { jqxTreeModule } from 'jqwidgets-ng/jqxtree';
import { jqxDataTableModule } from 'jqwidgets-ng/jqxdatatable';
import { jqxChartModule } from 'jqwidgets-ng/jqxchart';
import { jqxMenuModule } from 'jqwidgets-ng/jqxmenu';
import {NgModule} from '@angular/core';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';


@NgModule({
    exports: [
        // jqxLayoutModule,jqxTreeModule,jqxGridModule,
        // jqxDataTableModule,jqxChartModule,jqxMenuModule
    ]
  })
export class JqxModule { }