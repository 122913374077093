import { AppUserClaim } from "./app-user-claim";

export class AppUserAuth {
    userName: string = "";
    userImg: string = "";
    bearerToken: string = "";
    companyId: string = "";
    companyCode: string = "";
    countryId: string = "";
    countryCode: string = ""; 
    parentUserId: string = "";
    parentUserName: string = "";
    isAuthenticated: boolean = false;
    isFCA: boolean = false;
    isAdmin: boolean = false;
    userId: string;
    companyName: string;
companyPhone:string;
companyEmail: string;
userEmployeePosition: string;
    // claims: AppUserClaim[] = [];
    sideMenu: any[] = [];
    expressMenu: any[] = [];
    TwoFactorAuthRequired:boolean=false;
}
