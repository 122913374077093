export const messageCode = {
    changeTab: 1 ,
    requirementError: 3,
    savingSuccessfully: 4,

    deleteError: 55,
    deleteSuccessfully: 54,
    changeStatusError: 57,
    changeStatusSuccessfully: 56,
    archivedError: 69,
    archivedSuccessfully: 68,
    archivedConfirmation: 71,
    noFileSelected: 60,
    marketingCampaignCancelledSuccessfully: 83,
    SurveyIsTaken: 64,
    codeExist: 99,
    UnValidCode : 100 ,
    userAlreadyExists : 103 ,
    npsCloseStatusConfirmation:104,
    vinIsEmpty:105,
    UnableToSavehanges:77,
    ErrorSelectRows:107,
    NotificationSendSuccessfully: 108,
    UnableSendNotification:110,
    SubmitCampaign:109,
    reportsent:113,
    sendreportfailed:114,
    successrunservice:115,
    errorrunservice:116,
    DeleteConfirm:119,
    EmptySelection:120,
    WorkflowError:150,
};
