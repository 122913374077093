import { Component, ViewEncapsulation, OnInit, ViewChild, TemplateRef, EventEmitter, Output, Input, IterableDiffers } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { first } from 'rxjs/operators';
import { BaseService } from '@shared/index';
import { SecurityService } from '@shared/services';

@Component({
  selector: 'app-select-empoyee',
  templateUrl: './select-empoyee.component.html',
  styleUrls: ['./select-empoyee.component.scss']
})
export class SelectEmpoyeeComponent implements OnInit {
  formModalRef: BsModalRef | null;
  @ViewChild('selectUserModal') formModal: TemplateRef<any>;

  @Input('inputUser')
  inputUser: string;
  
  @Input('disabled')
  disabled: boolean;

  @Output('userId')
  userId: EventEmitter<any> = new EventEmitter<any>();
@Input('isGD')
isGD:boolean=false;
  users=[];
  activeStyleUserTable="";
  selectedUser:any;
  saveSelectUserButton=false;
  @ViewChild(MatSort ) sortUser: MatSort;

  searchValueUser:string;
userData={ userName:"", userId:""};
dataSourceUser=new MatTableDataSource(this.users);
displayedColumnsUser: string[] = [  'firstName', 'lastName','company','email'];
  applyFilterEmplyee(filterValue: string) {
    this.dataSourceUser.filter = filterValue.trim().toLowerCase();
  }
  constructor(private _BaseService: BaseService,private _modalService: BsModalService,private securityService:SecurityService) { }
  openedModel=false;
  ngOnDestroy(){
    if(this.openedModel){
      this.formModalRef.hide();
    }
   }
  ngOnInit() {
     ;
    this.loadLists();
  }
  
  loadLists(){
    if(this.isGD){
       ;
      this._BaseService.get('employee/GetbyCompanyId/'+this.securityService.securityObject.companyId).pipe(first()).subscribe(
        result=>{
          this.users = result.data;
          this.dataSourceUser = new MatTableDataSource(this.users);
          this.dataSourceUser.sort = this.sortUser;
          this.users.forEach(element => {
            if(element.employeeId==this.inputUser){
              this.selectedUser=element;
              this.saveSelectUser();
            }
          });
        });
    }
    else
    {
    this._BaseService.get('Employee').pipe(first()).subscribe(result => {
      this.users = result.data;

      this.dataSourceUser = new MatTableDataSource(this.users);
      this.dataSourceUser.sort = this.sortUser;
      this.users.forEach(element => {
        if(element.employeeId==this.inputUser){
          this.selectedUser=element;
          this.saveSelectUser();
        }
      });
     
    
    });
  }
  }

  saveSelectUser(){
     ;
    this.userData.userId=this.selectedUser.employeeId
    this.userData.userName=this.selectedUser.firstName + " " + this.selectedUser.lastName;
    this.userId.emit(this.userData);

    this.closeSelectUserModal();
  }
  selectUser(data){
    this.activeStyleUserTable=data.employeeId;
    this.selectedUser=data;
    this.saveSelectUserButton=true;

      }
closeSelectUserModal(){
  this.activeStyleUserTable='';
  this.saveSelectUserButton=false;
        this.formModalRef.hide();
        this.openedModel=false;
        
        }
openUserSelectModal(){
  this.openedModel=true;
          const configs = {
            keyboard: false,
              ignoreBackdropClick: true,
              class: ' modal-lg modal-primary masterFileModal animated fadeInRight',
              animated:false    }
          this.formModalRef = this._modalService.show(this.formModal, configs);
        }
        ngOnChanges(changes) {
          
         
         if(this.inputUser){
          
          this.users.forEach(element => {
             if(element.employeeId==this.inputUser){
               this.selectedUser=element;
               this.saveSelectUser();
             }
           });
          
         }
        }
}
