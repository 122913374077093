import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ModelSeriesOption, Result  } from '../_models';
import { environment } from '../../../environments/environment';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { threadId } from 'worker_threads';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CMSService {
    private canonicalName : string;
    private progressObserver: any;
    private progress$: Observable<number>;

    /**
     * @type {number}
     */
    private progress: number = 0;

    constructor(private http: HttpClient,private router:Router) {
      this.progress$ = new Observable(observer => {
        this.progressObserver = observer
    });
        if (localStorage.getItem("canonicalName") !== null) {
            this.canonicalName = localStorage.getItem('canonicalName') + '/';
        }else{
            this.canonicalName = "";
        }

    }
    IsUserCmsAdmin=false;
    disableTabs=false
    disableUser=false
//lockup data
public getObserver (): Observable<number> {
  return this.progress$;
}
caseObject:any
caseupdatedObject:any
createNewCase=false
//lookups
deleteCase(option: any[]) {

  return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSCase/delete-by-transaction` , option);
  }
savereportdata(list:any,measure){
  return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}Report/saveReport-indb/`+measure,list);
}

getLookUp(key?:string ) {
  return this.http.get<any>(`${environment.appUrl}${this.canonicalName}LookUp/getValues/${key}`);
}
getMax(key?:string ) {
  return this.http.get<any>(`${environment.appUrl}${this.canonicalName}CMSCase/max`);
}
//
    getAll(body?:any ) {
      return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSCategory`);
  }
  getmeasures(){
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}/Report/GetMeasures`);
  }
  getAllflags( ) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSFlag`);
}
getCaseflags(caseId ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSFlag/get-Caseflag-ByCaseId/`+caseId);
}
setflags(flagList,caseId) {
  return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSFlag/update-Case-Flage/`+caseId , flagList);
}
AddEmailtoCase(emailId,caseId) {

  return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSEmail/add-email-to-case/`+caseId , {"emailId":emailId});
}
checkContact(emailId,dateofcreate) {
  var body={"email":emailId,"dateofcreate":dateofcreate}
  return this.http.post<any>(`${environment.appUrl}${this.canonicalName}CMSEmail/check-contact/`,body);
}

  add(option: any) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCategory`, option);
}

update(option: any) {
    return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSCategory` , option);
}

updateStatus(option: any[], status:any) {
  var setstatus=true
  if(status=='A') setstatus=true
  if(status=='NA') setstatus=false
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCategory/change-status/`+ setstatus, option);

}

//check ouath
client_id='54836345725-njionuiopqcgej6a9nj000r86u7fisp6.apps.googleusercontent.com'
client_secret='j0kiOi70ogrFk_7RV43I4yF9'

async checkOauth(code?):Promise<Boolean>{
let url=window.location.href;
let baseUrl=url.substr(0,url.indexOf(url.split('/')[2]))+url.split('/')[2]
  if(code){
    //create link with code for first time to get token
    let endPoint=`https://accounts.google.com/o/oauth2/token?grant_type=authorization_code&code=${code}&client_id=${this.client_id}&client_secret=${this.client_secret}&redirect_uri=${baseUrl}`
    await this.http.post<any>(endPoint, null).subscribe(result=>{
      localStorage.removeItem('gmail_code')
      localStorage.removeItem('gmail_source')

      if(result.error)return false
      if(result.access_token){
        localStorage.setItem('gmail_token',JSON.stringify(result))
        if(result.refresh_token)localStorage.setItem('gmail_refresh_token',result.refresh_token)
        return true;
      }
    },()=>{return of(false);});

  }else{
    //exist user
    let token=localStorage.getItem('gmail_token')?JSON.parse(localStorage.getItem('gmail_token')):this.getCode(baseUrl)
    let endPoint=`https://accounts.google.com/o/oauth2/token?grant_type=refresh_token&refresh_token=${token.refresh_token}&client_id=${this.client_id}&client_secret=${this.client_secret}`
    await this.http.post<any>(endPoint, null).subscribe(result=>{
      if(result.error||!localStorage.getItem('gmail_refresh_token'))return this.getCode(baseUrl)
      if(result.access_token){
        localStorage.setItem('gmail_token',JSON.stringify(result))
        return true;
      }
    },()=>{return  false;});


  }
return false
}

getTokenByObject(endpoint){
  return this.http.post<any>(`${endpoint}`, null);

}
getCode(url?){
  localStorage.removeItem('gmail_source')
  let fullurl=window.location.href;
  let baseUrl=fullurl.substr(0,fullurl.indexOf(fullurl.split('/')[2]))+fullurl.split('/')[2]
  if(!baseUrl.endsWith('/'))baseUrl+='/'
  let navUrl=this.router.url
  localStorage.setItem('gmail_source',navUrl)
  ///baseUrl='urn:ietf:wg:oauth:2.0:oob'//
  let endPoint=`https://accounts.google.com/o/oauth2/v2/auth?client_id=${this.client_id}&response_type=code&scope=https://mail.google.com/%20email&access_type=offline&prompt=consent&redirect_uri=${baseUrl}`

  window.location.href = endPoint

}

delete(option: any[]) {

    return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSCategory/delete-by-transaction` , option);
    }

//source
getAllSources(body?:any ) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSSource`);
}

addSource(option: any) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSSource`, option);
}

updateSource(option: any) {
  return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSSource` , option);
}

updateSourceStatus(option: any[], status:any) {
var setstatus=true
if(status=='A') setstatus=true
if(status=='NA') setstatus=false
return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSSource/change-status/`+ setstatus, option);

}
deleteSource(option: any[]) {

    return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSSource/delete-by-transaction` , option);
    }

// end of source

//flags
getAllFlags(body?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSFlag`);
}

addFlag(option: any) {
return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSFlag`, option);
}

updateFlag(option: any) {
return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSFlag` , option);
}

updateFlagStatus(option: any[], status:any) {
var setstatus=true
if(status=='A') setstatus=true
if(status=='NA') setstatus=false
return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSFlag/change-status/`+ setstatus, option);

}
deleteFlag(option: any[]) {

  return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSFlag/delete-by-transaction` , option);
  }

// end of flags

//types
getAllTypes(body?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSType`);
}


addType(option: any) {
return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSType`, option);
}

updateType(option: any) {
return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSType` , option);
}

updateTypeStatus(option: any[], status:any) {
var setstatus=true
if(status=='A') setstatus=true
if(status=='NA') setstatus=false
return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSType/change-status/`+ setstatus, option);

}
deleteType(option: any[]) {

  return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSType/delete-by-transaction` , option);
  }

// end of types
//reasons
getAllReasons(body?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSReason`);
}
getAllReasonsCat(body?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSReason/ReasonCat`);
}

getAllSubSub(id?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSReason/Subsub`);
}

getAllSUBReasons(id?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSReason/sub`);
}

addReason(option: any) {
return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSReason`, option);
}

updateReason(reason,action,option: any) {
return this.http.post<any>(`${environment.appUrl}${this.canonicalName}CMSReason/${reason}/${action}` , option);
}

addSubRelated(reason,option: any) {
  return this.http.post<any>(`${environment.appUrl}${this.canonicalName}CMSReason/sub/${reason}` , option);
  }

addSubSubRelated(reason,option: any) {
    return this.http.post<any>(`${environment.appUrl}${this.canonicalName}CMSReason/subsub/${reason}` , option);
    }

updateReasonStatus(option: any[], status:any) {
var setstatus=true
if(status=='A') setstatus=true
if(status=='NA') setstatus=false
return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSReason/change-status/`+ setstatus, option);

}
deleteReason(option: any[]) {

  return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSReason/delete-by-transaction` , option);
  }


// end of reasons
//templates
getAllTemplates(body?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSTemplates`);
}
addTemplate(option: any) {
return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSTemplates`, option);
}

updateTemplate(option: any) {
return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSTemplates` , option);
}

updateTemplateStatus(option: any[], status:any) {
var setstatus=true
if(status=='A') setstatus=true
if(status=='NA') setstatus=false
return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSTemplates/change-status/`+ setstatus, option);

}
deleteTemplate(option: any[]) {

  return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSTemplates/delete-by-transaction` , option);
  }


// end of templates

//start case
SearchCase(body?:any) {

  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCase/Search/`,body);
}

ExportCases(body?:any ) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCase/excel-cases`,body);
}
ExportCommunications(body?:any ) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCase/excel-communications`,body);
}
ExportContacts(body?:any ) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCase/excel-contacts`,body);
}
ExportFlags(body?:any ) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCase/excel-flags`,body);
}

getAllCases(body?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSCase`);
}
checkDateOfContact(body?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSCase/checkDateOfContact/${body}`);
}
addCase(option: any) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCase`, option)

 //  .pipe(
  //   catchError(this.handleError)
  // );;
}
/* private handleError(error: HttpErrorResponse) {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
  }
  // return an observable with a user-facing error message
  return throwError(
    'Something bad happened; please try again later.');
}; */

//add contact us form'
addContactUs(option: any) {

  return this.http.post<any>(`${environment.appUrl}${this.canonicalName}CMSContactUs`, option);
}


//end of contact us form
updateCase(option: any) {

  return this.http.put<any>(`${environment.appUrl}${this.canonicalName}CMSCase`, option);
}
updateCaseVehicle(option: any) {
  return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSCase/UpdateCaseVehicle`, option);
}

getById(id?:any ) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSCase/${id}`);
}
getcaselogsBycaseno(caseno?:any ) {
  return this.http.get<any>(`${environment.appUrl}${this.canonicalName}CMSCase/GetCaseLog/${caseno}`);
}getViewcaselogsBycaseno(caseno?:any ) {
  return this.http.get<any>(`${environment.appUrl}${this.canonicalName}ViewCaseLog/${caseno}`);
}


getVehicleById(id?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSCase/vehicle/${id}`);
}
getVINById(id?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSCase/GetVINById/${id}`);
}

getVINDecode(id?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}VINDecoding/${id}`);
}

// getVINDecodeAll() {
//   return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}VINDecoding/all`);
// }

//end of case
getVinByCase(caseno?:any ) {
  return this.http.get<any>(`${environment.appUrl}${this.canonicalName}CMSCase/GetVinByCase/${caseno}`);
}

getAllService(vin?:any) {
  return this.http.get<any>(`${environment.appUrl}${this.canonicalName}CMSCase/GetVinHistory/${vin}`);
}

getAllHistory(vin?:any,report?:any ) {
  return this.http.get<any>(`${environment.appUrl}${this.canonicalName}Report/GetVinHistroy/${vin}/${report}`);
}



getReportByFilter(reportid:any,filter:any,viewid:any) {
  return this.http.post<any>(`${environment.appUrl}${this.canonicalName}Report/GetReportByFilter`,{"reportId":reportid,"viewId":viewid,"filterName":filter});
}
getReportByreportId(reportid:any)
{
  return this.http.get<any>(`${environment.appUrl}${this.canonicalName}Report/GetReport/${reportid}`);

}
getReportBy2Filter(reportid:any,filtername:any,filtervalue:any,filter2name:any,filter2value:any) {
  return this.http.post<any>(`${environment.appUrl}${this.canonicalName}Report/GetReportBy2Filter`,
  {"reportId":reportid,"viewId":filtervalue,"filterName":filtername,"filter2Value":filter2value,"filter2Name":filter2name});
}
//start contact
SearchContact(body?:any ) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSContact/search`,body);
}
SearchAll(body?:any ) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSContact/searchall`,body);
}
getAllContacts(caseno?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSContact/Case/${caseno}`);
    }
    getAllContactsForCustomerSearch(caseno?: any) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSContact/CustomerSearch/Case/${caseno}`);
    }
getcontactbyid(contactid?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSContact/Contact/${contactid}`);
}
getAllConttactUs() {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}MarketingCampaign/GetROCustomers`);
}

getCasesIds(contactId) {
  return this.http.get(`${environment.appUrl}${this.canonicalName}CMSContact/get-casesIds/${contactId}`);
}

getVinsByContactId(contactId) {
  return this.http.get(`${environment.appUrl}${this.canonicalName}CMSContact/get-Vins/${contactId}`);
}
searchROcustomer(filter){
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSContact/search-contact`,filter);

    }

addcustomerContact(item,caseno) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSContact/addcustomer-cmsContact/${caseno}`, item);
}
addcaseContact(item,caseno) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSContact/addcase-Contact/${caseno}`, item);
}
addContact(option: any) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSContact`, option);
}
updateContact(option: any) {
  return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSContact`, option);
}
addContactToCase(body:any,emailId?:string ) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSContact/Case/email/`,body);
}
AddEmployeeContact(body:any ) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSContact/Employee-Contact`,body);
}

//end of case

//start communication

getCommunicationsList(caseno?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/CaseList/${caseno}`);
}
  getCommunicationsTicketNo(ticktNo?: any, caseno?: any, threadId?:any) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/CaseThread/${ticktNo}/${caseno}/${threadId}`);
}
getAllCommunications(caseno?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/Case/${caseno}`);
}
getOne(threadId?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/${threadId}`);
}
getCC(threadId?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/cc/${threadId}`);
}
getAttachment(threadId?:any ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/Attachment/${threadId}`);
}
getattachmentByCaseNo(threadId?:any , caseno?:any) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/Attachment/${threadId}/${caseno}`);
}

addAttachment(option: any,threadId) {

  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/AttachmentAdd/${threadId}`, option);
}
addAttachmentManual(option: any) {

  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/AddAttachmentManual/`, option);
}
addContactUsAttachment(option: any,contactId) {

  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/AttachmentContactAdd/${contactId}`, option);
}
sendEmail(option: any) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/mail/`, option);
}
sendEmailContactUs(option: any) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/mailContactUs/`, option);
}
sendEmailAouth(option: any) {
  if(!option)option={}
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/mailaouth/`, option);
}

sendEmailCommWithToken(caseno,option?: any) {
  if(!option)option={}
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/mailaouth/${caseno}`, option);
}
sendEmailWithToken(caseno,option?: any) {
  if(!option)option={}
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCase/mail/${caseno}`, option);
}

updateAttachment(option: any,caseno:any,threadId) {
  return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/Attachment/${caseno}/${threadId}`, option);
}

downloadFile(caseno:any,file:any){
  let str=`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/download/${caseno}/${file}`
  return str;
  // const  headers=new HttpHeaders({ name: 'Accept', value: 'application/json' })
  // const requestOptions: Object = {
  //     /* other options here */
  //     headers:headers,
  //     responseType:'blob'
  //   }
  // return this.http.get<any>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/download/${caseno}/${file}`,
  // requestOptions
  // );

}
 uploadAttachment(fileToUpload: File,id:any) {
   var formData = new FormData();
   const  headers=new HttpHeaders({ name: 'Accept', value: 'application/json' })

   formData.append("file", fileToUpload, fileToUpload.name)  //formData.append('caseId', id);
  // let options = [{ 'Content-Type': 'multipart/form-data' }];
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/UploadAttachment/` + id,
  formData,{headers:headers} );
 }
/*   uploadAttachment(fileToUpload: File,caseid:any) {
    var headers = new HttpHeaders();

    headers.append ( 'Accept',  'application/json' )
    headers.append ( 'itemAlias',  'file' )

   //headers.append('file', fileToUpload);
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/Attachment/${caseid}`,{headers:headers});

} */
uploadMe(files: File[],caseid:any){
return null;
}


/**
* Set interval for frequency with which Observable inside Promise will share data with subscribers.
*
* @param interval
*/
setUploadUpdateInterval (interval: number): void {
  setInterval(() => {}, interval);
}
addCommunication(option: any) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication`, option);
}
addCommunicationCC(option: any,threadId) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/cc/${threadId}`, option);
}
updateCommunication(option: any) {
  return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication`, option);
}
updateCommunicationCC(option: any,threadId) {
  return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/cc/${threadId}`, option);
}
deleteCommunication(threadId: any) {
  return this.http.delete<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/${threadId}`);
}
deleteAttachment(option: any) {
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}CMSCaseCommunication/AttachmentDelete`,option);
}

//end of communications

/////EMAILS
updateInboxStatus(option: any[], status:any) {
  return this.http.put<any>(`${environment.appUrl}${this.canonicalName}CMSEmail/updatestatus/`+status, option);

}
junkList(option: any[], status:any) {
  return this.http.post<any>(`${environment.appUrl}${this.canonicalName}CMSEmail/CaseStatus-List/`+status, option);

}
getAllEmails(body?:any ) {
  return this.http.post<any>(`${environment.appUrl}${this.canonicalName}CMSEmail/mails/`,body);
}
getAllEmailsTest(body?:any ) {
  return this.http.get<any>(`${environment.appUrl}${this.canonicalName}CMSEmail/mailsTest/`);
}
saveCode(body?:any ) {
  return this.http.post<any>(`${environment.appUrl}${this.canonicalName}CMSEmail/saveCode/`,body);
}

routeGmail(body?:any ) {
  return this.http.get<any>(`${body}`);
}

getEmail(id:string ) {
  return this.http.get<any>(`${environment.appUrl}${this.canonicalName}CMSEmail/${id}`);
}
markEmailRead(id:string,body:any ) {
  return this.http.post<any>(`${environment.appUrl}${this.canonicalName}CMSEmail/${id}`,body);
}
/////END EMAILS
updateModelSeriesStatus(option: any[], status:any) {
  var setstatus=true
  if(status=='A') setstatus=true
  if(status=='NA') setstatus=false
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}ModelSeries/change-status/`+ setstatus, option);

}

deleteModelSeries(option: any[]) {

    return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}ModelSeries/delete-by-transaction` , option);
    }
deleteModelSeriesOptions( cpos: string) {

    return this.http.delete<Result>(`${environment.appUrl}${this.canonicalName}ModelSeries/${cpos}` );
    }
    deleteModelSeriesPrices(data: any[]) {

        return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}pricelist/delete-by-transaction` , data);
        }


        //
//survey
checkContactSurvey(email,caseno) {
  return this.http.get<any>(`${environment.appUrl}CMSQuest/${email}/${caseno}` );
}
updatecasesurvy(caseno,status){

  return this.http.get<any>(`${environment.appUrl}CMSQuest/updateCaseSurvy/${caseno}/${status}` );

}
addSurvey(data:any) {
  return this.http.post<any>(`${environment.appUrl}CMSQuest/`,data );
}
////////
getCity(id:string ) {
  return this.http.get<any>(`${environment.appUrl}${this.canonicalName}City/country/${id}`);
}

getDealers(id:string ) {
  return this.http.get<any>(`${environment.appUrl}${this.canonicalName}Company/GetCompanyByContryId/${id}`);
}
companyGetAllDealers() {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Company/get-Dealers`);
}
getcontactusDealers(id:string ) {
  return this.http.get<any>(`${environment.appUrl}${this.canonicalName}Company/GetActiveCompanyByContryId/${id}`);
}
getBranch(companyId:string ,  CityId:string) {
  return this.http.get<any>(`${environment.appUrl}${this.canonicalName}Branch/getBranches-By-CompanyId-CityId/${companyId}/${CityId}`);
}
getListForCombo() {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Country/get-list-combo`);
}
getByCountryId(countryId: string) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}City/country/` + countryId);
}
getDealersForCase() {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}pricelist/get-dealers-for-case`);
}
modelSeriesUpdateStatus(option: ModelSeriesOption[], status:any) {
  var setstatus=true
  if(status=='A') setstatus=true
  if(status=='NA') setstatus=false
  return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}option/change-status/`+ setstatus, option);

}
modelSeriesDelete(option: ModelSeriesOption[]) {
  return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}option/delete-by-transaction` , option);
}
getModelList( make_id:string) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}ModelSeries/get-model-combo/`+make_id);
}
getBranchesByDealer(id:string) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Employee/getBranches/${id}`);
}
getEmployeesByDealer(id:string) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Employee/getByDealer/${id}`);
}
modelSeriesGetDealers() {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}pricelist/get-dealers-combo`);
}
modelSeriesGetDealersForCase() {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}pricelist/get-dealers-for-case`);
}
modelSeriesGetMakeList( ) {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}ModelSeries/get-make-combo`);
}
usersGetAll() {
  return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}user`);
}
search(filterValue:string,dataList:string[]=[]):any{
  var newDataList:string[]=[]
   if(filterValue=="" ){
      newDataList=dataList
      return
   }
   var nameArr = filterValue.toLowerCase().split(',')
   if(filterValue.indexOf(' ')>-1)  nameArr = filterValue.toLowerCase().split(' ')
   var definedColumns = Object.keys(dataList[0])
   for(var i=0;i<definedColumns.length;i++){
       var element=definedColumns[i]
       newDataList=dataList.filter(a=>a[element] && nameArr.includes(a[element].toLowerCase()  ) )
       if(newDataList.length>0 ){
         return newDataList
      }
  }
   return newDataList

}
}

