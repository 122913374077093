import { Component, OnInit,ViewChild,TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService , ErrorService } from '@shared/index';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUPComponent implements OnInit {
  //  start defined save button & close tag to save things
  //
  //
  //
  @ViewChild('formAddEditModal') formModal: TemplateRef<any>;
  tabNewContant=false;
  changeNewContant=false;
  laddaSearchProgress: boolean | number = false;
  saveContant=false;
  userForm: FormGroup;
  dangerAlert=false;
  title=''
  content:any
  caseDays:any;
  alerts: any[] = [{
    type: '',
    msg: ``,
    timeout: 1
  }];
  resolved(data){
    
  }
  userId='';
  tabTitel='General';
  type='';
  canApprove=false;
  creator = false;
  assigned = false;
  FCRTypeName: string;
  formModalRef: BsModalRef | null;

  //
  //
  //
 // end of defined save button & close tag to save things
 FCRTypes= [];
 gDFCRNumber= [];
 concernType= [];
 fCROwner= [];
 visibality= [];
 systemGroup= [];
 componentGroup= [];
 failureCode= [];
 branch=[];
 sub:any;
 getBranch(data){
   let url ='branch/company/'
  this.baseService.get(url + data.companyId).pipe(first()).subscribe(result => {
    this.branch = result.data;
    
  });

}
addPasswerd(event){
  this.userForm.get('password').setValue(event)
}
 changTabContact=false;
showVinHistory=true;
 changetap(tap) {
  // this.tabTitel = tap;
  if(this.changeNewContant || this.changTabContact){
    this.confirmClose(tap);
    }else{
this.tabTitel=tap  }
 
}
indeterminate = false;
  labelPosition = 'after';
fcaOwnerId=null;
  constructor(private baseService: BaseService , private _errorService: ErrorService,public router:Router, 
     private fb: FormBuilder, private route:ActivatedRoute,private spinner: NgxSpinnerService,
     private location: Location, private _modalService: BsModalService,
  ) {

  
   }
   comapnyList=[];
   positionList=[];
   branchList=[];
   departmentList=[];
   loadLists(){
     let urlCompanyService ='company/get-list-combo'
    this.baseService.get(urlCompanyService).pipe(first()).subscribe(result => { 
       
      this.comapnyList = result.data;
    });
    let urlPositionService ='EmployeePosition/get-list-combo';
    this.baseService.get(urlPositionService).pipe(first()).subscribe(result => {    
      this.positionList = result.data;
  });
  let urlDepartmentService ='department/get-list-combo';
  this.baseService.get(urlDepartmentService).pipe(first()).subscribe(result => {    
       this.departmentList = result.data;
     });
  }
  getBranchAndDepartment(data){
  this.changeNewContant=true;
  let urlBranch='branch/company/'
  this.baseService.get(urlBranch + data).pipe(first()).subscribe(result => {    
    this.branchList = result.data;
  });
  // this._departmentService.getByCompanyId(data.companyId).pipe(first()).subscribe(result => {    
  //   this.departmentList = result.data;
  // });
  }

   submitregistration=true;
   concernNo:any;
   fillForm(entityData){
    const controls = this.userForm.controls;

    Object.keys(controls).forEach(key => {

      if(entityData[key]!=null){
        this.userForm.get(key).setValue(entityData[key]);

      }
      this.getBranch(entityData);

    })
   }
   getUserById(){
     let urlUser='FCRConcern/get-ById/'
    this.baseService.get(urlUser + this.userId).pipe(first()).subscribe(result => {
      var taskData = result.data;
      // caseStatus: "01"

      this.caseDays=result.data.caseDays;
      this.concernNo=result.data.concernNo;

      const controls = this.userForm.controls;

      Object.keys(controls).forEach(key => {

        if(taskData[key]!=null){
          this.userForm.get(key).setValue(taskData[key]);

        }
        this.getBranch(taskData);

      })
      this.changeNewContant=false;
 

// if (taskData.caseStatus== "03"){this.userForm.get('reopen').value.disable()}


        },
        (error) => {


            this.spinner.hide();

          // Utils.createMessage(this._notifications, 'Save Result', error.error.message, NotificationType.Error);
        });

   }
  ngOnInit() {
    this.initForm();
    this.loadLists();
  }

  closeCase(caseStatus){
    this.baseService.post(`FCRConcern/change-CaseStatus/${this.userId}/${caseStatus}` , {}).pipe(first()).subscribe(result => { 
      this._errorService.getAPMassage("4").pipe(first()).subscribe(errorMsg => {
        this.errorMsgLocation(errorMsg);
  
      });
    });

  }

  private initForm(): void {
    this.userForm = this.fb.group({
      "regstrationid": new FormControl(null),
      "employeeid": new FormControl(null),
      "userid": new FormControl(null),
      "firstname": new FormControl(null, [Validators.required ]),
      "lastname": new FormControl(null, [Validators.required ]),
      "middlename": new FormControl(null),
      "email": new FormControl(null, [Validators.required ]),
      "phone": new FormControl(null),
      "mobile": new FormControl(null, [Validators.required ]),
      "password": new FormControl(null, [Validators.required ]),
      "companyid": new FormControl(null, [Validators.required ]),
      "departmentid": new FormControl(null, [Validators.required ]),
      "branchid": new FormControl(null),
      "positionid": new FormControl(null, [Validators.required ]),
      "companyemployeenumber": new FormControl(null),
      "sid": new FormControl(null, [Validators.required ]),
      "dealerEndDate": new FormControl(null),
      "dealerStartDate": new FormControl(null, [Validators.required ]),
      "birthday": new FormControl(null, [Validators.required ]),
     });
  }
  MSG='';
  MSGTitle='';
  saveAddEdit() {
    this.saveContant=true;
    this.laddaSearchProgress=1;
    const controls = this.userForm.controls;
    Object.keys(controls).forEach(key => {
    controls[key].markAsTouched();
    });
    this.saveContant=true;
    if(this.userForm.status=="VALID"){
    if(this.userForm.value.userid==null){
    // Add Service
    let urlUser='User/register';
    this.baseService.post(urlUser , this.userForm.value).pipe(first()).subscribe(result => {
      this.laddaSearchProgress=false;

      if(result.isSuccess){
        this.changeNewContant=false;
        this.saveContant=false;
        this.submitregistration=false;
        this._errorService.getAPMassage("4").pipe(first()).subscribe(result => {
         this.errorMsgLocation(result);
       });
       this._errorService.getAPMassage("72").pipe(first()).subscribe(resultMsg => {
        this.MSG=resultMsg.data.messageText;
        this.MSGTitle=resultMsg.data.buttonOneTitle;
      });
      }else{
        this._errorService.getDBMassage(result.errorCode+"").pipe(first()).subscribe(result => {
          this.errorMsgLocation(result);
         });
      }
  
    // this.router.navigate(link);
    },
    (error) => {
      this.laddaSearchProgress=false;
    });
  }else{
  }
  }else{
    this._errorService.getAPMassage("3").pipe(first()).subscribe(result => {
      this.errorMsgLocation(result);
     });
     this.laddaSearchProgress=false;

  }

  }

  errorMsgLocation(result){
    if(result.data.location=='Pop Up'){
      this.errorMsg(result.data);
      }else if(result.data.location=='Top green'){
        this.addAlert('success',result.data.messageText);

      }else if(result.data.location=='Top red'){
        this.addAlert('danger',result.data.messageText);

      }else{
        this.addAlert('danger','Failed to Save Changes');
      }
   }
   errorMsg(data){

    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey : false,
      title: data.messageTitle,
      text: data.messageText,
      type: data.icon.toLowerCase(),
      showCancelButton: data.buttonTwoTitle ? true :false,
      confirmButtonColor: '#FF8989',
      cancelButtonColor: '#BCBCCB',
      // iconColor:  '#FF8989',
      cancelButtonText: data.buttonTwoTitle,
      confirmButtonText: data.buttonOneTitle
    }).then((result) => {
      if (result.value) {




      }else{

      }


    });
  }

  addAlert(typeData,msgData): void {

    this.dangerAlert=false;

    var time = 3000;
    if(typeData=='danger'){
      this.dangerAlert=true;
      time=99999999999;
    }
    if(this.alerts.length==0){
      this.alerts.push({
      type: typeData,
      msg: msgData,
      timeout: time
    });
    }else{
      this.alerts.splice(0, 1)
       this.alerts.push({
        type: typeData,
        msg: msgData,
      timeout: time
    });
    }

  }



  closeSaveData(){
    if(this.type=='add'){
      this.initForm();
    }
    else{
      this.getUserById();
    }

  }



onClosedAlert(dismissedAlert): void {
  this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  this.alerts=[];
this.dangerAlert=false;
}
 
  changeTab(tab){
    if(this.changeNewContant || this.tabNewContant){
      this.confirmClose(tab);
    }
      else{
     this.tabTitel=tab;
    }

  }
  confirmClose(titel){
    this._errorService.getAPMassage('1').pipe(first()).subscribe(result => {

      var data = result.data;
      Swal.fire({
        allowOutsideClick: false,
      allowEscapeKey : false,
      title: data.messageTitle,
      text: data.messageText,
      type: data.icon.toLowerCase(),
      showCancelButton: data.buttonTwoTitle ? true :false,
      confirmButtonColor: '#FF8989',
      cancelButtonColor: '#BCBCCB',
      // iconColor:  '#FF8989',
      cancelButtonText: data.buttonTwoTitle,
      confirmButtonText: data.buttonOneTitle

      }).then((result) => {
        if (result.value) {

        }else{
          this.changeNewContant=false;
          this.tabTitel=titel
        }
      });
        });


  }

  restCheckboxValue(atrr){
      this.changeNewContant=true;
      this.userForm.get(atrr).setValue(false);
    }
setCheckboxValue(event,atrr){
  this.changeNewContant=true;
  if(event){
    this.userForm.get(atrr).setValue(true);

  }
}
 goBack(){this.location.back();} 
dateChanged(evt,row){
  if(evt){
    this.changeNewContant=true
    let selectedDate = new Date(evt);
    selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
    this.userForm.get(row).setValue(selectedDate);
  }
   }

   taskStatuses: any[] = [];
   escalationList=[];
   users=[];

 


  //DDL
  
 
 
  formType="add";

  openPopup= false;
  reopen(){
    this.formType="add";
    this.openPopup=true;
    this.openFormModal();
  
  }
  openFormModal() {
  
    const configs = {
      keyboard: false,
        ignoreBackdropClick: true,
        class: ' modal-lg modal-primary masterFileModal  animated fadeInRight',
        animated:false    }
    this.formModalRef = this._modalService.show(this.formModal, configs);
    this.openPopup=true;
  }
  commentReopen='';
  confirmClose2(){
    this._errorService.getAPMassage('1').pipe(first()).subscribe(result => {
  
      var data = result.data;
      Swal.fire({
        allowOutsideClick: false,
      allowEscapeKey : false,
      title: data.messageTitle,
      text: data.messageText,
      type: data.icon.toLowerCase(),
      showCancelButton: data.buttonTwoTitle ? true :false,
      confirmButtonColor: '#FF8989',
      cancelButtonColor: '#BCBCCB',
      // iconColor:  '#FF8989',
      cancelButtonText: data.buttonTwoTitle,
      confirmButtonText: data.buttonOneTitle
  
      }).then((result) => {
        if (result.value) {
  
  
  
          // swal(
          //   couponstatus+'!',
          //   'The Coupon has been '+couponstatus+'.',
          //   'success'
          // )
        }else{
          this.formModalRef.hide();
          this.openPopup=false;
          this.changeNewContantComment=false;
  
        }
      });
        });
  
  
  }
  
 
  closeAddEditModal() {
    if(this.changeNewContantComment){
this.confirmClose2();

    }else{
      this.formModalRef.hide();
      this.openPopup=false;
      this.changeNewContantComment=false;
    }

  }
  saveComment(){
let url ='FCRConcern/get-ById/'
this.baseService.get(url + this.userId).pipe(first()).subscribe(result => {
  var taskData = result.data;
  // caseStatus: "01"
this.userForm.enable();

  const controls = this.userForm.controls;

  Object.keys(controls).forEach(key => {

    if(taskData[key]!=null){
      this.userForm.get(key).setValue(taskData[key]);

    }

  })
  var reopenDate = new Date();
  reopenDate.setMinutes(reopenDate.getMinutes() -  reopenDate.getTimezoneOffset());
  this.userForm.get('reopenDate').setValue(reopenDate);
  this.userForm.get('reasonReOpening').setValue(this.commentReopen);
  this.userForm.get('caseStatus').setValue('03');
  this.baseService.put('FCRConcern/edit' ,this.userForm.value).pipe(first()).subscribe(result => {
    this.laddaSearchProgress=false;
    this.changeNewContant=false;
    this.saveContant=false;
    this.fillForm(result.data);
    this._errorService.getAPMassage("4").pipe(first()).subscribe(result => {
      this.errorMsgLocation(result);
      this.formModalRef.hide();

    });
   },
   (error) => {
    this.laddaSearchProgress=false;



   });
})

  }
  changeNewContantComment=false;
  
}





// It was in the last Design may be return agaen

// "sourceId": new FormControl(null, [Validators.required ]),
// source= [];
