export class ReportFilter {
    name: string;
    value?: string;
  }
  enum ReportCategory{
    DashPanel,
    VehicleSalesAndCarParcPanel,
    ServicePanel,
    RetentionPanel,
    LostCustomerPanel,
    PartsSalesPanel,
    CommoditiesPanel,
    LogisticsAndSupplyChainPanel,
    PartsAnalysisPanel,
    RevenueOpportunityPanel,  
  }
  export class Report {
    public constructor(workbookLink:string, reportName: string){
      this.workbookLink = workbookLink;
      this.reportLink = reportName;    
    }
    reportCategory: ReportCategory;
    reportName: string;
    reportLink: string;
    category?: string;
    reportId?: string;
    claim: string;
    url?: string;
    favorite?: boolean;
    workbookLink: string;
    reportFilters: ReportFilter[];
  }
  
  export class ReportCategoryD {
    public constructor(name:string, isCollapsed: boolean){
      this.name = name;
      this.isCollapsed = isCollapsed;    
    }
    name: string;
    isCollapsed: boolean;
  }