import { Injectable } from "@angular/core";
import { CanActivate, CanDeactivate, ActivatedRouteSnapshot, Router } from "@angular/router";
import swal from 'sweetalert2';
import {  BaseService } from '@shared/index';
import { first } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class AntiBackGuardService implements  CanDeactivate<any> {
  constructor(private _BaseService: BaseService,) {

  }
   canDeactivate(component: any) {
     if (component.changeNewContant) {
       return this.canDea(component);
     }
     return true;
   }
  canDea(component:any): Promise<any> | boolean {
       if (component.changeNewContant) {
         return new Promise((resolve, reject) => {
             this._BaseService.get('Message/1').pipe(first()).subscribe(result => {
        
        const data = result.data;

        swal.fire({
          allowOutsideClick: false,
        allowEscapeKey : false,
        title: data.messageTitle,
        text: data.messageText,
        type: data.icon.toLowerCase(),
        showCancelButton: data.buttonTwoTitle ? true :false,
        confirmButtonColor: '#FF8989',
        cancelButtonColor: '#BCBCCB',
        cancelButtonText: data.buttonTwoTitle,
        confirmButtonText: data.buttonOneTitle


           }).then( action => {
             if (action.value) {
              resolve(false);
             } else {
              resolve(true);
             }
         }); });
         });
       } else {
         return true;
       }
     }
  }