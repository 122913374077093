export class CaseCallLog {
    logid: string;
    marketingCampaignId: string;
    vin: string;
    statusType: string;
    statusId: string;
    tranDate?: Date;
    tranTime?: string;
    remarks: string;
    userId: string;
}

