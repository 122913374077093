export class BPTaskAttachment {
    taskId: string;
    attachmentId: string;
    bPAttachment: BPAttachment;

}

export class BPTask {

    taskId: string;
    taskName: string;
    taskDescription: string;
    taskTypeId: string;
    taskTypeName: string;
    taskStatusId: string;
    assigneeTypeId: string;
    expectedCompletionDate?: Date;
    assigneeUserId: string;
    actualCompletionDate?: Date;
    taskReport: string;
    completionPercent?: number;
    campaignScope: string;
    bPCompletionStatusId: string;
    marketingSupportRequired?: number;
    createdOn?: Date;
    createdBy: string;
    modifiedOn?: Date;
    modifiedBy: string;
    //bPTaskAttachment?: BPTaskAttachment[] = [];
    bpAttachments?: BPAttachment[] = [];
}

export class BPTaskType {

    taskTypeId: string;
    taskTypeName: string;
}

export class BPAttachment {
    constructor(
        attachmentFilePath: string,
        attachmentDescription: string,

        createdBy: string,
        modifiedBy: string
    ) {
        this.attachmentFilePath = attachmentFilePath;
        this.attachmentDescription = attachmentDescription;
        this.createdBy = createdBy;
        this.ModifiedBy = modifiedBy;
    }
    attachmentId: string;
    attachmentDescription: string;
    attachmentFilePath: string;
    createdOn?: Date;
    createdBy: string;
    modifiedOn?: Date;
    ModifiedBy: string;
}

export class TaskFilter {

    taskName?: string;
    taskTypeId?: string;
    taskStatusId?: string;
    createdOnFrom?: Date = null;
    createdOnTo?: Date = null;
    expectedCompletionDateFrom?: Date;
    expectedCompletionDateTo?: Date;
    completionPercentFrom?: number;
    completionPercentTo?: number;
    userCreated?: string;
    assigneeUserId?: string;
    assigneeTypeId?: string;
    isActive?: boolean;
}

export class BPActivity {
    activityId: string;
    activityName: string;
    remark: string;
    startDatetime?: Date;
    endDatetime?: Date;
    location: string;
    activityTypeId: string;
    activityStatusId: string;
    approvalBy: string;
    approvalStatus: string;
    onlineMeetingID: string;
    createdOn?: Date;
    createdBy: string;
    modifiedOn?: Date;
    modifiedBy: string;
    bPActivityAttendee?: BPActivityAttendee[] = [];
    bPTask?: BPTask[] = [];
    bpAttachments?: BPAttachment[] = [];
}
export class FCRFilter{
    select?:string;
    wip?:string;
    concernNo?:string;
    companyId?:string;
    concernTypeId?:string;
    model?:string;
    make?:string;
    seriesPos?:string;
    displacement?:string;
    modelYear?:string;
    systemGroupId?:string;
    componentGroupId?:string;
    failureCodeId?:string;
    severity:string;
    part?:string;
    visability?:string;
    VIN?:string;
    createdBy?:string;
    fcaOwner?:string;
    caseStatus?:string;
    isEscalated?:boolean;

    pageNumber:number;
    pageSize:number;
  }
  
export class dealerFilter{
    select?:string;
    companyNo?:string;
    title?:string;
    type?:string;
    createdOn?:string;
    startDate?:string;
    endDate?:string;
    scope?:string;
    status?:string;
    valid?:string;
    
    concernNo?:string;
    companyId?:string;
    concernTypeId?:string;
    model?:string;
    series?:string;
    modelYear?:string;
    systemGroupId?:string;
    componentGroupId?:string;
    failureCodeId?:string;
    severity:string;
    part?:string;
    visability?:string;
    VIN?:string;
    createdBy?:string;
    fcaOwner?:string;
    caseStatus?:string;
  
  }
export class ActivityFilter {

    createdOnFrom?: Date;
    createdOnTo?: Date;
    createdBy?: string;
    activityName?: string;
    activityType?: string;
    activityTypeId?: string;
    startDatetimeFrom?: Date;
    startDatetimeTo?: Date;
    endDatetime?: Date;
    activityStatus?: string;
    activityStatusId?: string;
    isActive?: boolean;

}

export class BPActivityType {

    activityTypeId: string;
    activityTypeName: string;
    requireApproval: boolean;
}

export class BPActivityAttendee {
    activityAttendeeId: string;
    activityId: string;
    userId: string;
    attendeeType: string;
    firstname: string;
    lastname: string;
    mobile: string;
    email: string;
    company: string;
    attendeeStatus: string;
    remarks: string;
}
export class BPTripActivity {
    constructor(bpActivity: BPActivity){
        this.bpActivity = bpActivity;
    }
    bpActivity: BPActivity;
}
export class BPTrip {
    tripId: string;
    tTripName: string;
    tripTypeId: string;
    remark: string;
    plannedStartDate?: Date;
    plannedEndDate?: Date;
    actualStartDate?: Date;
    actualEndDate?: Date;
    approvedBy: string;
    tripStatus: string;
    createdOn?: Date;
    createdBy: string;
    modifiedOn?: Date;
    modifiedBy: string;
    
    //bPExpense?: BPExpense[] = [];
    bPTripDestinations?: BPTripDestinations[] = [];
    bpTripActivity?: BPTripActivity[] = [];
    bpExpense?: BPExpense[] = [];
    bpTripMeals?: BPExpenseMeals[] = [];
}

export class TripFilter {

    createdOnFrom?: any;
    createdOnTo?: any;
    plannedStartDateFrom?: any;
    plannedStartDateTo?: any;
    plannedEndDateFrom?: any;
    plannedEndDateTo?: any;
    tripName?: string;
    tripStatus?: string;
    isActive?: boolean;

}

export class BPTripType {

    tripTypeId: string;
    tripTypeName: string;
    needApproval: boolean;
}

export class BPTripDestinations{
    destinationId: string;
    tripId: string;
    startDateTime?: Date;
    endDateTime?: Date;
    countryId: string;
    cityId: string;
    companyId: string;
    branchId: string;
    remarks: string;

    countryName: string;
    cityName: string;
    companyName : string;
    branchName : string;
}

export class BPExpense{
    expenseId: string;
    expenseTypeId: string;
    expenseTypeName: string;
    tripId: string;
    expenseDate?: Date;
    remark: string;
    currencyRate: number;
    currencyId: string;
    currencyName: string;
    companyId: string;
    localAmount: Number;
    amount: number;
    isAllawence: boolean;
    total: number;
    bpAttachments?: BPAttachment[] = [];
}


export class BPExpenseMeals{
    expenseId: string;
    destinationId: string;
    tripId: string;

    allownceDate?: Date;
    travelHours: number;
    allownce: number;
    noOfMeals: Number;
    countryId: string;
    deuctionPercent: Number;
    deductionAmount: number;
    total: number;
   

}