import { SheetHeader } from './';

export class Preset {
    presetId: string;
    name: string;
    isActive: boolean = true;
    isSftpBrige: boolean = false;
    presetCode: string;
    sftpFolderName: string;
    sftpProfileId: string;
    sheetHeader: SheetHeader[] = [];
}
export class Cpreset {
    Name: string;
    Description: string;
    status: boolean;
    PermissionId: number;
    reportName: string;
    PresetId: string;
}
export class Message {
    messageId: string;
    messageType: string;
    messageTypeText: string;
    messageBody: string;
    messageSubject: string;
}
