
import { Component, OnInit,AfterViewInit, ViewEncapsulation,OnDestroy, ViewChild, TemplateRef, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import swal from 'sweetalert2';
import { GeneralConfigurationService } from '@shared/services';
import { catchError, first } from 'rxjs/operators';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {  MessageService, CMSService , BaseService} from '@shared/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Subscription, of, throwError } from 'rxjs';

@Component({
  selector: 'app-attachment-general-new',
  templateUrl: './attachment-general-new.component.html',
  styleUrls: ['./attachment-general-new.component.scss']
})


export class AttachmentGeneralNewComponent implements OnInit, AfterViewInit,OnDestroy {
  subscription: Subscription = new Subscription();

  //use this list as output in table
  attachmentList=[{
       "name":'',
    "file":'',
    "attachementtype":''
 
  }]
  
  @Input() childId:any;
  @Input() childGuid:any;
  @Input() urlpath:any;
  @Input('NavigateParm') NavigateParm: boolean;
  @Input() attachmentFilePathName='name'
  @Input() attachmentDescriptionName='file'//we want it Remarks 
  @Input() showAddButton=true
  @Input() showDeleteButton=true
  @Input() express=false
  @Input()  maxSize=20.0
  @Input() maxFiles=20
  @Input() accept='*.*'
  remarks='';

  @Input() attachmentFilesList
  @Output('allAttachmentList')
  allAttachmentList: EventEmitter<any> = new EventEmitter<any>();

  
  constructor(private spinner: NgxSpinnerService,private  generalService:GeneralConfigurationService, private fb: FormBuilder, private _messageService:MessageService,
    private _modalService: BsModalService, private _baseService: BaseService,) { }

  displayedColumns: string[] = [ 'select', 'Name', 'File','attchmentDate'];
  dataSource = new MatTableDataSource<any>(this.attachmentList);
  selection = new SelectionModel<any>(true, []);
  @ViewChild('fileInput') fileInput: ElementRef;//we get it from html
  @ViewChild(MatSort) sortAttachment: MatSort;
  formAddAttachmentsModalRef: BsModalRef;
  
  @ViewChild('formAddAttachmentsModal') formAddAttachmentsModal: TemplateRef<any>;
  configs =  {
    keyboard: false,
      ignoreBackdropClick: true,
      class: ' modal-lg modal-primary masterFileModal animated fadeInRight',
      animated:false    }
customClasses = {
    sortAscending: 'datatable-icon-down',
    sortDescending: 'datatable-icon-up'
};

  openAddAttachmentsModal() {
    if(this.express){// if express value that in attachment.html =true it will drows , false form  
      let inputElement: HTMLElement = this.fileInput.nativeElement as HTMLElement;
      inputElement.click();
      return;
    }
    //it will show the form 
    this.formAddAttachmentsModalRef = this._modalService.show(this.formAddAttachmentsModal, this.configs);
    this.attachmentForm.reset()
}
attachmentForm: FormGroup;

private initForm(): void {
    
  this.attachmentForm = this.fb.group({
    'attachmentFilePathName': new FormControl(null, [Validators.required]),
    'attachmentDescriptionName': new FormControl(null, [Validators.required]),
    'attachementtype': new FormControl(null),
    'Remarks': new FormControl(null),
    'threadid':new FormControl(null)
    
   });
}

closeAddAttachmentsModal() {
  this.fileToUpload = null;

    this.formAddAttachmentsModalRef.hide();
}

getAllFiles(){
   
  this.generalService.getList(this.urlpath,this.childId).pipe(
    catchError(error => {
      if (error.status === 204) {
        // Handle 204 response (No Content)
        return of(null); // Return an observable of null as a placeholder
      } else {
        // Handle other errors
        console.error('An error occurred:', error);
        // You can re-throw the error or return an error observable
        return throwError('An error occurred while making the request.');
      }
    })
  )
  .subscribe((result)=>{
    if(result){
      this.attachmentFilesList=result.data;

      this.attachmentList=[]    
    
      this.dataSource   = new MatTableDataSource(this.attachmentFilesList);
      this.dataSource.sort = this.sortAttachment
      if(this.attachmentFilesList )
      {
        if( !this.attachmentFilesList['filepath']){
          this.attachmentList['name']=this.attachmentFilesList['filepath']
          this.attachmentList['file']=this.attachmentFilesList['remarks']
  
        }
        this.attachmentList['name']=this.attachmentFilesList['filepath']
        this.attachmentList['file']=this.attachmentFilesList['remarks']
  
        this.attachmentList=this.attachmentFilesList;
        
        
      }
    }
    if(result== null){
       
      this.attachmentFilesList=null
      this.dataSource   = new MatTableDataSource(this.attachmentFilesList);
    }
    
  }
)
}
  ngOnInit() {
    this.initForm();
    this.getAllFiles();


    this.subscription.add( this._baseService.currentAction.subscribe(action => this.actionMsg(action)));

  }
  ngOnDestroy(){
    this.subscription.unsubscribe();

}

  actionMsg(data) {
   if (data !== '') {
     this[data]();
      }
 }

 newMessage(messageid, action) {
   this._baseService.changeMessage({id: messageid, action : action});
 }


  ngAfterViewInit(){

  }
  ngOnChanges(changes) {
     
    if (this.attachmentList.length > 0) {
      this.dataSource   = new MatTableDataSource();
      this.dataSource.sort = this.sortAttachment
    }
    
    
    }
    formModalRef: BsModalRef | null;
  @ViewChild('formAddEditModal') formModal: TemplateRef<any>;
  openFormModal() {
    
    const configs = {
      keyboard: false,
        ignoreBackdropClick: true,
        class: ' modal-lg modal-primary masterFileModal  animated fadeInRight',
        animated:false    }
    this.formModalRef = this._modalService.show(this.formModal, configs);
    this.openPopup=true;
}
closeAddEditModal() {
  if(this.changeNewContant){
this.confirmClose();

  }else{
    this.closeAddAttachmentsModal();
    this.openPopup=false;
    this.changeNewContant=false;
    this.saveContant=false;
  }
    
}
        openPopup=false;
        changeNewContant=false;
        saveContant=false;
confirmClose(){
      // It's still old
  this._messageService.getAPMassage('1').pipe(first()).subscribe(result => {
    
    var data = result.data;
    swal.fire({
      allowOutsideClick: false,
    allowEscapeKey : false,
    title: data.messageTitle,
    text: data.messageText,
    type: data.icon.toLowerCase(),
    showCancelButton: data.buttonTwoTitle ? true :false,
    confirmButtonColor: '#FF8989',
    cancelButtonColor: '#BCBCCB',
    // iconColor:  '#FF8989',
    cancelButtonText: data.buttonTwoTitle,
    confirmButtonText: data.buttonOneTitle
  
    }).then((result) => {
      if (result.value) {
       
  
        
        // swal(
        //   couponstatus+'!',
        //   'The Coupon has been '+couponstatus+'.',
        //   'success'
        // )
      }else{
        this.closeAddAttachmentsModal();
        this.openPopup=false;
        this.changeNewContant=false;
        this.saveContant=false;
  
      }
    });
      });
      

}
addNewItem(){
  this.openFormModal();
}
 

  openDialog() {

  }
  sortData(taps) {

  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    if(this.dataSource.data)
   {const numRows = this.dataSource.data.length;
    return numSelected === numRows;}
  }
  
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }
  
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.taskName + 1}`;
  }
  fileToUpload: File = null;
  errSize=false

  orgSize=0
  loading=false
  handleFileInput(files: FileList) {
    
    this.fileSize=0
    this.attachmentForm.controls['attachmentDescriptionName'].setValue(files.item(0).name.split('.')[0])
    this.attachmentForm.controls['attachmentFilePathName'].setValue(files.item(0).name.split('.')[1])
    //check size
    files.item(0).size 
    var _size = files[0].size;
    this.orgSize=_size/1024/1024
    var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
    i=0;while(_size>900){_size/=1024;i++;}
    var exactSize = (Math.round(_size*100)/100)+' '+fSExt[i];
    this.fileSize=exactSize;
    this.fileToUpload=files[0];
    if(this.orgSize>this.maxSize){
      this.errSize=true
    }else{
      this.errSize=false
    }
    this.changeNewContant=true;
    if(this.express){
      this.saveAddEdit()
    }    
}
fileSize:any

laddaSearchProgress=false

saveAddEdit(){
   
if(this.errSize){

  if(!this.express)this.closeAddAttachmentsModal();
  this.openPopup=false;
  this.changeNewContant=false;
  this.saveContant=false;
  return  
}
this.spinner.show();
  var file=this.fileToUpload
  if(!file || !file.name) return
  this.laddaSearchProgress=true
 
  //this.uploadFileToActivity()
  
  var remark=this.attachmentForm.get('Remarks').value;
  this.loading=true
  this.generalService.uploadAttachment(file,this.urlpath,this.childId, this.remarks).pipe(first()).subscribe(result =>{
    this.getAllFiles()
    //this.attachmentList.push({"name":this.attachmentForm.controls['name'].value,"file":result.data,"attachementtype":this.attachmentForm.controls['attachementtype'].value    
  //})

//   if(this.attachmentFilePathName!='name'){
//     let array=this.attachmentList
//     Object.keys(this.attachmentList).forEach(key => {
//       array[key][this.attachmentFilePathName] =array[key]['name']? array[key]['name']:array[key][this.attachmentFilePathName];
//       array[key][this.attachmentDescriptionName] = array[key]['file']? array[key]['file']:array[key][this.attachmentDescriptionName];
//       delete array[key]['name'];
//       delete array[key]['file'];

//   })
//   this.attachmentList=array
// }
this.laddaSearchProgress=false;
this.spinner.hide();

    //this.attachmentFilePathName='name'
    //this.attachmentDescriptionName='file'
    //this.allAttachmentList.emit( this.attachmentList);
    this.attachmentForm.controls['threadid'].setValue(this.childId)
    this.dataSource   = new MatTableDataSource(this.attachmentList);
    this.dataSource.sort = this.sortAttachment
    if(!this.express)this.closeAddAttachmentsModal();
    this.openPopup=false;
    this.changeNewContant=false;
    this.saveContant=false;
    var allFiles=[]
    allFiles.push(file)
    this.loading=false
    },err=>{
  this.laddaSearchProgress=false
  this.loading=false
    }
    )
    

}

downloadFile(event:any){
 let str= this.generalService.downloadFile(this.urlpath,this.childId, event)
    let pwa = window.open(str);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert( 'Please disable your Pop-up blocker and try again.');
    } 
  


}
deleteAttachments(){
   
  if(this.selection.selected.length==0)  {    
    return;
  }
  ////uncomment below and edit to delete attachment
    //  this.cmsService.deleteAttachment(this.selection.selected).pipe(first()).subscribe(() =>{
    //  })
  
      //this.attachmentFilePathName='name'
      //this.attachmentDescriptionName='file'
    let filenames=[]
    let attachmentInDb=[]
    ////delete the file from server

    this.selection.selected.forEach(item => {
      let index: number = this.attachmentFilesList.findIndex(d => d === item);
      this.attachmentFilesList.splice(index,1)
      this.dataSource   = new MatTableDataSource(this.attachmentFilesList);
      this.dataSource.sort = this.sortAttachment
    // filenames.push(item['file'])
       
       filenames.push(item);
       
      
     
         })
          
       this.generalService.delete(this.urlpath,this.childId,filenames).pipe(first()).subscribe(() =>{
 
         this.selection = new SelectionModel<Element>(true, []);
    //

    this.allAttachmentList.emit( this.attachmentFilesList);

  })
}
}

