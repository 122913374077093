import { Permission } from "./permission";

export class PermissionGroup {
    permissionGroupId?: number;
    permissionGroupName: string;
    permissionGroupControl: string;
    permissionGroupOrder: number;
    hasparentPermissionGroup: boolean;
    isDelete: boolean;
    parentGroupId: number;
    permission: Permission[];
}
