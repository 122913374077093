import { Component, OnInit } from '@angular/core';
import {  MessageService, BaseService, SecurityService } from '@shared/index';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-counts',
  templateUrl: './counts.component.html',
  styleUrls: ['./counts.component.scss']
})
export class CountsComponent implements OnInit {
countData;
  constructor(private _messageServie: MessageService,
  private securityService: SecurityService,
    private _baseService: BaseService) { }
  ngOnInit(): void {
    this.getInitData();
  }
getInitData(){
  
  this._baseService.get('Lookup/getCounts').pipe(first()).subscribe(result => {

    this.countData = result.data;



},
    (error) => {



        // Utils.createMessage(this._notifications, 'Save Result', error.error.message, NotificationType.Error);
    });

}
}
