import { element } from 'protractor';
import { Component, OnInit, ViewChild, ViewEncapsulation, ChangeDetectorRef, Input, Output, EventEmitter, TemplateRef, IterableDiffers } from '@angular/core';


import {
    DomSanitizer,
    SafeHtml,
    SafeUrl,
    SafeStyle
} from '@angular/platform-browser';



@Component({
  selector: 'app-wizard-steps',
  templateUrl: './wizard-steps.component.html',
  styleUrls: ['./wizard-steps.component.scss'],
  
  encapsulation: ViewEncapsulation.None
})


export class WizardStepsComponent implements OnInit {

    @Input('list')
    list: string[];

    @Input('selected')
    selected: string;

    ngOnInit(): void {
       
    }

}
