import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppUserAuth, AppUser } from '../_models';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { first, map } from 'rxjs/operators';
import { Result } from '../_models';
import { DOCUMENT } from '@angular/common';
import * as jose from 'jose';
import { Guid } from 'guid-typescript';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class SecurityService {
    private _renderer2: Renderer2;
    private canonicalName: string;
    securityObject: AppUserAuth = new AppUserAuth();
    securityMsg = `
    <div class='text-left'>
    This website contains highly confidential, non-public information, 
    including information relating to vehicle sales performance, which you have agreed to keep confidential. 
    You are reminded of your confidentiality obligations with respect to the information contained on this website. </div>
    `;

    constructor(
        public router: Router,
        private http: HttpClient,
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private _document: Document
    ) {
        if (!this.securityObject.userId) {
            if (localStorage.getItem('securityObject')) {
                this.securityObject = JSON.parse(localStorage.getItem('securityObject'));
                this.showHelpIcon();
            } else {
                this.logout();
                this.hideHelp();
            }
        }
        if (localStorage.getItem('canonicalName') !== null) {
            this.canonicalName = localStorage.getItem('canonicalName') + '/';
        } else {
            this.canonicalName = '';
        }
    }
    verfayUser(id, status) {
        return this.http.get<Result>(
            `${environment.appUrl}${this.canonicalName}User/vreify-registration/${id}/${status}`
        );
    }
    login(appUser: AppUser) {
        this.resetSecurityObject();

        return this.http.post<any>(`${environment.appUrl}${this.canonicalName}user/authenticate/`, appUser).pipe(
            tap((resp) => {
                // Use object assign to update the current object
                // NOTE: Don't create a new AppUserAuth object
                //       because that destroys all references to object
                // Object.assign(this.securityObject, resp);
                // localStorage.setItem("securityObject", JSON.stringify(this.securityObject));
                if (resp.changePassword === true) {
                    this.router.navigate(['login/resetPassword', resp.userId]);
                } else {
                    //Object.assign(this.securityObject, resp);
                    if (resp.isActive) {

                        setTimeout(()=> 
                        swal.fire({
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            title: 'Dealer Disclaimer',
                            html: this.securityMsg,
                            showCancelButton: true,
                            confirmButtonColor: '#FF8989',
                            cancelButtonColor: '#BCBCCB',
                            cancelButtonText: 'Disagree',
                            confirmButtonText: 'Agree'
                        }).then((result) => {
                            if (result.value) {
                                //agree

                                this.UpdateUserAgreement('true')
                                    .pipe(first())
                                    .subscribe((result) => { });
                            } else {
                                this.router.navigate(['login']);
                                //disagree

                                this.UpdateUserAgreement('false')
                                    .pipe(first())
                                    .subscribe((result) => { });
                            }
                        })
                        ,2500)
                    }

                    localStorage.removeItem('twoFactor');

                    this.securityObject = new AppUserAuth();
                    this.securityObject.userName = resp['userName'];
                    this.securityObject.companyCode = resp['companyCode'];
                    this.securityObject.countryId = resp['countryId'];
                    this.securityObject.countryCode = resp['countryCode'];
                    this.securityObject.bearerToken = resp['bearerToken'];
                    this.securityObject.parentUserId = resp['parentUserId'];
                    this.securityObject.parentUserName = resp['parentUserName'];
                    this.securityObject.isAuthenticated = resp['isAuthenticated'];
                    this.securityObject.sideMenu = resp['sideMenu'];
                    this.securityObject.expressMenu = resp['expressMenu'];
                    this.securityObject.userImg = resp['userImg'];
                    this.securityObject.userId = resp['userId'];
                    this.securityObject.isFCA = resp['isFCA'];
                    this.securityObject.TwoFactorAuthRequired = resp['twoFactorAuthRequired'];
                    this.securityObject.companyId = resp['companyId'];
                    this.securityObject.companyName = resp['companyName'];
                    this.securityObject.companyPhone = resp['companyPhone'];
                    this.securityObject.companyEmail = resp['companyEmail'];
                    this.securityObject.userEmployeePosition = resp['userEmployeePosition'];
                    localStorage.setItem('securityObject', JSON.stringify(this.securityObject));
                    sessionStorage.setItem('securityObject', JSON.stringify(this.securityObject));

                    this.createJWTForZendesk().then((token) => {
                        localStorage.setItem('zdjwt-x0', token);
                    });
                    // Object.keys(this.securityObject).forEach(key => {
                    //     if(resp[key]!=null){
                    //         this.securityObject[key].setValue(resp[key]);
                    //       }

                    //     })
                }
            })
        );
    }


    SSOlogin(email) {
        this.resetSecurityObject();

        return this.http.get<any>(`${environment.appUrl}${this.canonicalName}user/AuthUser/`+ email).pipe(
            tap((resp) => {
                // Use object assign to update the current object
                // NOTE: Don't create a new AppUserAuth object
                //       because that destroys all references to object
                // Object.assign(this.securityObject, resp);
                // localStorage.setItem("securityObject", JSON.stringify(this.securityObject));
                if (resp.changePassword === true) {
                    this.router.navigate(['login/resetPassword', resp.userId]);
                } else {
                    //Object.assign(this.securityObject, resp);
                    if (resp.isActive) {

                        setTimeout(()=> 
                        swal.fire({
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            title: 'Dealer Disclaimer',
                            html: this.securityMsg,
                            showCancelButton: true,
                            confirmButtonColor: '#FF8989',
                            cancelButtonColor: '#BCBCCB',
                            cancelButtonText: 'Disagree',
                            confirmButtonText: 'Agree'
                        }).then((result) => {
                            if (result.value) {
                                //agree

                                this.UpdateUserAgreement('true')
                                    .pipe(first())
                                    .subscribe((result) => { });
                            } else {
                                this.router.navigate(['login']);
                                //disagree

                                this.UpdateUserAgreement('false')
                                    .pipe(first())
                                    .subscribe((result) => { });
                            }
                        })
                        ,2500)
                    }

                    localStorage.removeItem('twoFactor');

                    this.securityObject = new AppUserAuth();
                    this.securityObject.userName = resp['userName'];
                    this.securityObject.companyCode = resp['companyCode'];
                    this.securityObject.countryId = resp['countryId'];
                    this.securityObject.countryCode = resp['countryCode'];
                    this.securityObject.bearerToken = resp['bearerToken'];
                    this.securityObject.parentUserId = resp['parentUserId'];
                    this.securityObject.parentUserName = resp['parentUserName'];
                    this.securityObject.isAuthenticated = resp['isAuthenticated'];
                    this.securityObject.sideMenu = resp['sideMenu'];
                    this.securityObject.expressMenu = resp['expressMenu'];
                    this.securityObject.userImg = resp['userImg'];
                    this.securityObject.userId = resp['userId'];
                    this.securityObject.isFCA = resp['isFCA'];
                    this.securityObject.TwoFactorAuthRequired = resp['twoFactorAuthRequired'];
                    this.securityObject.companyId = resp['companyId'];
                    this.securityObject.companyName = resp['companyName'];
                    this.securityObject.companyPhone = resp['companyPhone'];
                    this.securityObject.companyEmail = resp['companyEmail'];
                    this.securityObject.userEmployeePosition = resp['userEmployeePosition'];
                    localStorage.setItem('securityObject', JSON.stringify(this.securityObject));
                    sessionStorage.setItem('securityObject', JSON.stringify(this.securityObject));

                    this.createJWTForZendesk().then((token) => {
                        localStorage.setItem('zdjwt-x0', token);
                    });
                    // Object.keys(this.securityObject).forEach(key => {
                    //     if(resp[key]!=null){
                    //         this.securityObject[key].setValue(resp[key]);
                    //       }

                    //     })
                }
            })
        );
    }

    

    async createJWTForZendesk() {
        const secret = new TextEncoder().encode('ba5afeb9952edf2764f4fd8188dfae22');

        const alg = 'HS256';

        const jwt = await new jose.SignJWT({
            name: 'Radwa Fouad',
            email: 'rmostafa@technosignage.com'
        })
            .setProtectedHeader({ alg })
            .setIssuedAt()
            .setJti(Guid.create().toString())
            .sign(secret);
        return jwt;
    }

    logout(): void {
        this.resetSecurityObject();
    }
    UpdateUserAgreement(agree: string) {
        return this.http.get<any>(`${environment.appUrl}${this.canonicalName}User/UpdateUserAgreement/` + agree);
    }
    resetSecurityObject(): void {
        localStorage.clear();
        localStorage.setItem('userdata', null);

        localStorage.removeItem('securityObject');
        sessionStorage.removeItem('securityObject');
        localStorage.removeItem('recentProfiles');
        localStorage.removeItem('recentSftpProfiles');

        if (this.securityObject) {
            this.securityObject.userName = '';
            this.securityObject.companyCode = '';
            this.securityObject.countryId = '';
            this.securityObject.countryCode = '';
            this.securityObject.bearerToken = '';
            this.securityObject.parentUserId = '';
            this.securityObject.parentUserName = '';
            this.securityObject.isAuthenticated = false;
        }
        // this.securityObject.claims = [];
    }

    // This method can be called a couple of different ways
    // *hasClaim="'claimType'"  // Assumes claimValue is true
    // *hasClaim="'claimType:value'"  // Compares claimValue to value
    // *hasClaim="['claimType1','claimType2:value','claimType3']"

    // permationControl
    // to back permationControl remove the comment of the two function hasClaim and isClaimValid with return ret. and comment return true
    // note that permationControl can't be null

    hasClaim(claimType: any, claimValue?: any) {
        // let ret: boolean = false;
        // // See if an array of values was passed in.
        // if (typeof claimType === "string") {
        //     ret = this.isClaimValid(claimType, claimValue);
        // }
        // else {
        //     let claims: string[] = claimType;
        //     if (claims) {
        //         for (let index = 0; index < claims.length; index++) {
        //
        //             ret = this.isClaimValid(claims[index]);
        //             // If one is successful, then let them in
        //             if (ret) {
        //                 break;
        //             }
        //         }
        //     }
        // }
        //         return ret;

        return true;
    }

    private isClaimValid(claimType: string, claimValue?: string): boolean {
        // let ret: boolean = false;
        // let auth: AppUserAuth = null;

        // // Retrieve security object
        // auth = this.securityObject;
        // if (auth) {
        //     // See if the claim type has a value
        //     // *hasClaim="'claimType:value'"
        //     if (claimType.indexOf(":") >= 0) {
        //         let words: string[] = claimType.split(":");
        //         claimType = words[0].toLowerCase();
        //         claimValue = words[1];
        //     }
        //     else {
        //         claimType = claimType.toLowerCase();
        //         // Either get the claim value, or assume 'true'
        //         claimValue = claimValue ? claimValue : "true";
        //     }
        //     // Attempt to find the claim

        //     ret = auth.claims.find(c =>
        //         c.permissionControl.toLowerCase() == claimType &&
        //         c.permissionValue.toString() == claimValue) != null;
        // }
        //         return ret;
        return true;
    }
    getOTP(userCode) {
        return this.http.post<any>(`${environment.appUrl}${this.canonicalName}User/checkotp`, userCode);
    }

    getOTPEncoded(userCode) {
        return this.http.post<any>(`${environment.appUrl}${this.canonicalName}User/encodedotp/`, userCode);
    }
    showHelpIcon() {
        this._renderer2 = this.rendererFactory.createRenderer(null, null);
        const JWTScript = this._renderer2.createElement('script');
        JWTScript.innerHTML = `
                    window.zESettings = {
                        webWidget: {
                            authenticate: {
                                jwtFn: function (callback) {
                                    callback( localStorage.getItem('zdjwt-x0'));
                                }
                            }
                        }
                    };
              `;
        const script = this._renderer2.createElement('script');
        script.id = 'ze-snippet';
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=abfe0ea8-4f07-4feb-a183-c081c08ebc0e';
        this._renderer2.appendChild(this._document.body, JWTScript);
        this._renderer2.appendChild(this._document.body, script);
        const element02 = document.querySelector('iframe[data-product="web_widget"]');
        const element03 = document.querySelector('iframe#launcher');
        const element04 = document.querySelector('iframe#webWidget');
        if (element02) element02.classList.toggle('d-none');
        if (element03) element03.classList.toggle('d-none');
        if (element04) element04.classList.toggle('d-none');
    }

    hideHelp() {
        const element = document.getElementById('ze-snippet');
        const element02 = document.querySelector('iframe[data-product="web_widget"]');
        const element03 = document.querySelector('iframe#launcher');
        const element04 = document.querySelector('iframe#webWidget');
        if (element) element.remove();
        if (element02) element02.classList.toggle('d-none');
        if (element03) element03.classList.toggle('d-none');
        if (element04) element04.classList.toggle('d-none');
    }
}
