
import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef, Input, Output, EventEmitter, HostListener } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import { first } from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseService, messageCode, SecurityService, ExportToExcelService , FCRFilter, User} from '@shared/index';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

import { BehaviorSubject, Subscription } from 'rxjs';



@Component({
  selector: 'app-fcr',
  templateUrl: './fcr.component.html',
  styleUrls: ['./fcr.component.scss']
})
export class FCRComponent implements OnInit {
  collapsed=true;
  changeSearchValue=false;
  searchValue;
  hide=true;
  loadingExport=false
  @ViewChild('myGrid') myGrid: jqxGridComponent;
  selectedList=[];
  subscription: Subscription = new Subscription();

  collapsedFn(){
    this.collapsed=!this.collapsed;
  this.changeSearchValue=false;
  }

  actionsList=[
    // {name:'Activate'},
    {name:'Archive'}
    ,{name:'Export Selection'}
  ];
  actionStatus=null;

  selection = new SelectionModel(true, []);
  laddaSearchProgress: boolean | number = false;
  FCRList=[];
  dataSourceFCR=new MatTableDataSource(this.FCRList);
  @ViewChild(MatSort ) sortTask: MatSort;
  selected: any;
  selected2: any;
  searchUserId='';
  alerts: any[] = [{
    type: '',
    msg: ``,
    timeout: 1
  }];
  dangerAlert=false;
  sortData(data){}
  displayedColumnsFCRList: string[] = [  'select','isFCAUpdate','concernNo', 'companyName','concernTypeName','componentGroupName','systemGroupName','failureCodeName','severity','visability','caseStatus',"escalated",'caseDays','createdByName','fcaOwnerName','isGDUpdate'];

  FCRFilter = new FCRFilter();

  FCRListType = [];
  FCRListStatus=[];
  FCRListSeverity=[];
  fcrViability=[];
  fcrCaseStatus=[];
  FCRListComponentGroup=[];
  FCRListSystemGroup=[];
  FCRListFailureCode=[];
  fcrDistributer=[];
  makeLst=[];
  modelLst=[];
  createdByList=[];
  fcaOwnerList=[];
  // will chang to form


storedFCRSearchValues=JSON.parse(localStorage.getItem('storedFCRSearchValues')) ? JSON.parse(localStorage.getItem('storedFCRSearchValues')) : {};

  ActiveDisAllList=[
    {name:'All',value:null},{name:'Active',value:true},{name:'Inactive',value:false}
  ];

  datesUpdated(startEndDate,start,end){

    if(startEndDate.endDate){
      let endDate = new Date(startEndDate.endDate);
      endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());
      this.FCRFilter[end]=endDate;
    }

      if(startEndDate.startDate){

    let startDate = new Date(startEndDate.startDate);
    startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset());
    this.FCRFilter[start]= startDate;
      }




  }
  dateChanged(evt,row){

  if(evt){
    let selectedDate = new Date(evt);
    selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
    this.FCRFilter[row]=selectedDate;
  }
   }



  constructor(private excelService:ExportToExcelService,private _BaseService: BaseService,public securityService: SecurityService,
      public router:Router,
  ) { 
  }

  ngOnInit() {
    Object.keys(this.storedFCRSearchValues).forEach(key => {
      this.FCRFilter[key]=this.storedFCRSearchValues[key];
    })
    this.FCRFilter.pageNumber=0;
    this.FCRFilter.pageSize=20;
    this.FCRFilter.isEscalated=null;
    this.getAllFCR(this.FCRFilter);
    this.loadLists();

    this.subscription.add( this._BaseService.currentAction.subscribe(action => this.actionMsg(action)));


  }
  ngOnDestroy(){
    this.subscription.unsubscribe();

}

  actionMsg(data) {
    if (data !== '') {
      this[data]();
       }
  }
  newMessage(messageid, action) {
    this._BaseService.changeMessage({id: messageid, action : action});
  }
  

  applyFilter(filterValue: string) {
    this.dataSourceFCR.filter = filterValue.trim().toLowerCase();
  }
  loadLists(){
    this.searchValue='';

    this._BaseService.get('FCRConcernType/get-All').pipe(first()).subscribe(result => { 
      this.FCRListType = result.data;

    });
    this._BaseService.get('FCRCompomemtGroup/get-All').pipe(first()).subscribe(result => { 
      this.FCRListComponentGroup = result.data;

    });
    this._BaseService.get('FCRSystemGroup/get-All/').pipe(first()).subscribe(result => { 
      this.FCRListSystemGroup = result.data;

    });
    this._BaseService.get('FCRFailureCode/drop-dowen-list').pipe(first()).subscribe(result => { 
      this.FCRListFailureCode = result.data;

    });
    this._BaseService.get('FCRLookup/Get-Lookup-List/Severity').pipe(first()).subscribe(result => { 
      this.FCRListSeverity = result.data;

    });
    this._BaseService.get('FCRLookup/Get-Lookup-List/Visability').pipe(first()).subscribe(result => { 
      this.fcrViability = result.data;

    });
    this._BaseService.get('FCRLookup/Get-Lookup-List/Case Status').pipe(first()).subscribe(result => { 
      this.fcrCaseStatus = result.data;

    });
     this._BaseService.get('company/get-list-combo-IsDealer').pipe(first()).subscribe(result => { 
      this.fcrDistributer = result.data;

    });


    this._BaseService.get('FCRLookup/getmakes').pipe(first()).subscribe(result=>{
      this.makeLst=result.data;
    });

    this._BaseService.get('User/GetUsersIsDelars').pipe(first()).subscribe(result => { 
      this.createdByList = result.data;

    });
    this._BaseService.get('User/GetUsersIsFca').pipe(first()).subscribe(result => { 
      this.fcaOwnerList = result.data;

    });
  }
  getmodelLst(){
    
    this.changeSearchValue=true;
    if(this.FCRFilter.make)
    this._BaseService.get('FCRLookup/getmodelsbymake/' + this.FCRFilter.make).pipe(first()).subscribe(result=>{
      this.modelLst=result.data;
    });
    else{
      this.modelLst=[];
    }
  }


  selectAction(){
    if(this.actionStatus=='Activate')
    {
      this.actionStatus=null;

     // this.changeStatus('true');
    }
    if(this.actionStatus=='Archive')
    {
      this.actionStatus=null;

     // this.newMessage(messageCode.archivedConfirmation,'delet');
      this.delet();
    }
    if(this.actionStatus=='Inactivate')
    {
      this.actionStatus=null;

     // this.changeStatus('false');
    }

    else if(this.actionStatus=='Export Selection'){
      if(this.selection.selected.length==0)  {    

        this.actionStatus=null
        this.newMessage(messageCode.noFileSelected,'');
 
        return;
      }

      this.ExportExcelFileSelected()
    }
    this.actionStatus=null;

  }


  delet(){


    this._BaseService.post('FCRConcern/delete-List/',this.selection.selected).pipe(first()).subscribe(result => {
      // Utils.createMessage(this._notifications, 'Success Delete', 'Success Delete', NotificationType.Success);
      this.selection = new SelectionModel(true, []);
      this.actionStatus=null;

      if(result){
        this.newMessage(messageCode.archivedSuccessfully,'');
              this.getAllFCR(this.storedFCRSearchValues);

           }
      if(!result){
        
        this.newMessage(messageCode.archivedError,'');
      }
    },
    (error) => {

        // var msg= errorService.errorMessage(error,true);

      // Utils.createMessage(this._notifications, 'Save Result', error.error.message, NotificationType.Error);
    });

  }

  ExportExcelFileSelected() {
    this.getCasesNumbers(this.selection.selected)
    this.getExportData()

} 

pageNunmber = 0;
pageSize = 20;
totalPages = 4;
inGettingData = false;

@HostListener('window:scroll', ['$event'])
handleScroll(event) {
  if ( this.pageNunmber >= this.totalPages) {return }
  const elem = event.currentTarget;
  const pos = (elem.scrollTop || elem.scrollTop) + elem.offsetHeight;
  const max = elem.scrollHeight;
// pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
// inGettingData one call to get data
 if (pos >= max  && !this.inGettingData )   {
    // Do your action here
    this.inGettingData = true;

    this.getAllFCR(this.FCRFilter);

  }

}

casesNumbersList=[]
getCasesNumbers(array:any){
  const items = array;
  this.casesNumbersList=[]
  Object.keys(items).forEach(key => {
  //  if(this.selection.selected[key])
  this.casesNumbersList.push(array[key]['concernNo'])
      //  if (this.selection.selected[key].getError('isRequired')){
      //     this.caseForm.get(key).setErrors ( null );
      //  }
      
    })
}




   resetFilter(){
this.FCRFilter=new FCRFilter();
this.FCRFilter.pageNumber=0;
this.FCRFilter.pageSize=20;
this.FCRFilter.isEscalated=null;
this.serchFCR(this.FCRFilter)

//this.getAllFCR(this.FCRFilter);
}
   getAllFCR(FCRListSearch){
    this.actionStatus=null;

     this.laddaSearchProgress=1;
     localStorage.setItem('storedFCRSearchValues', JSON.stringify(FCRListSearch));

     this._BaseService.post('FCRConcern/Search', FCRListSearch).pipe(first()).subscribe(result => {
       this.tableListJQ(this.FCRList);
       if (this.pageNunmber === 0 ||this.pageNunmber==null) {
        this.FCRList = result.data;
        this.totalPages = result.lookupData / this.pageSize;
        
      } else {
        //this.FCRList=[];
        this.FCRList =this.FCRList.concat(result.data);
      }
      this.pageNunmber++;
      FCRListSearch.pageNumber=this.pageNunmber;
      FCRListSearch.pageSize=20;
      this.inGettingData=false;

      this.FCRFilter.pageNumber=this.pageNunmber;
      this.FCRFilter.pageSize=20;


       this.dataSourceFCR = new MatTableDataSource(this.FCRList);
       this.dataSourceFCR.sort = this.sortTask;
       this.laddaSearchProgress=false;

     },
     (error) => {
      this.laddaSearchProgress=false;
     }
     
     );
   };

  //  rendergridrows = (params: any): any => {
  //   this.getAllFCR(this.FCRFilter)
  //   let data = this.FCRList;
  //   return data;
  // }


  AddNewItem(){
    this.router.navigate(['fcr/addEditFcr','1','add']);

  }

  serchFCR(FCRFilter){
    this.FCRFilter.pageNumber=null;
    this.pageNunmber=null;
    this.getAllFCR(FCRFilter)
  }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataSourceFCR.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.taskName + 1}`;
    }
   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceFCR.data.length;
    return numSelected === numRows;
  }

  entityEdit(FCRData){

    this.router.navigate(['fcr/addEditFcr',FCRData.concernId,'edit']);

  }


getExportData():any
{
 this.loadingExport=true
  this._BaseService.post('FCRConcern/excel-cases',this.casesNumbersList).pipe().subscribe(resultcases => {
    this._BaseService.post('FCRConcernVins/excel-vinlist',this.casesNumbersList).pipe().subscribe(resultVins => {
      this._BaseService.post('FCRCommunication/excel-Communicationlist',this.casesNumbersList).pipe().subscribe(resultcommunications => {
        this._BaseService.post('FCRCagris/excel-Cagrislist',this.casesNumbersList).pipe().subscribe(resultCagris => {          
          this._BaseService.post('FCRShipping/excel-Shippinglist',this.casesNumbersList).pipe().subscribe(resultShipping => {          
            this.excelService.exportAsExcelFileFCR(resultcases.data,'Cases List',resultVins.data,resultcommunications.data,resultCagris.data,resultShipping.data);
        ()=>{this.loadingExport=false}},
        ()=>{this.loadingExport=false})},
        ()=>{this.loadingExport=false})},
        ()=>{this.loadingExport=false})},
        ()=>{this.loadingExport=false})},
        ()=>{this.loadingExport=false})

}


source: any;  
dataAdapter: any ;
detTabList=[];
count: number = 0;

onFilter(): void {
  if (this.count > 1) {
      let filterinfo = this.myGrid.getfilterinformation();
      let eventData = 'Triggered "filter" event';
      for (let i = 0; i < filterinfo.length; i += 1) {
          let eventData = 'Filter Column: ' + filterinfo[i].filtercolumntext;
         
      }
  }
  this.count++;
}

myGridOnRowSelect(event: any): void {
  
if(event.args.row){
  this.selectedList.push(event.args.row);
}else{
  this.selectedList=this.source.localdata
}
 

};

myGridOnRowUnselect(event: any): void {
  

  if(event.args.row){
    let index: number = this.selectedList.findIndex(d => d === event.args.row);
    this.selectedList.splice(index,1)
  }else{
    this.selectedList=[];
  }
};

Rowdoubleclick(event: any): void 
{
    this.router.navigate(['fcr/addEditFcr',event.args.row.bounddata.concernId,'edit']);

}
    
getWidth() : any {           
  return '97%';
}

showTable(list,property){
  let tableList = list.filter(element => element.statusName == property);
  this.detTabList = tableList;
  this.tableListJQ(tableList);

}


columns: any[] =
[
  { text: '#', dataField: 'concernNo', width: '10%' }, 
      { text: 'company Name', dataField: 'companyName', width: '15%', filtertype: 'range', cellsformat: 'dd-MMM-yyyy'  }, 
      { text: 'concern Type Name', dataField: 'concernTypeName', width: '15%' },
      { text: 'component Group Name', dataField: 'componentGroupName', width: '15%', cellsformat: 'dd-MMM-yyyy' },
      { text: 'system Group Name', dataField: 'systemGroupName', width: '15%'}, 
      { text: 'failure Code Name', dataField: 'failureCodeName', width: '15%'}, 
      { text: 'severity', dataField: 'severity', width: '15%'}, 
      { text: 'visability', dataField: 'visability', width: '15%'}, 
      { text: 'case Status', dataField: 'caseStatus', width: '15%'}, 
      { text: 'escalated', dataField: 'escalation', width: '15%'}, 
      { text: 'case Days', dataField: 'caseDays', width: '15%' },
      { text: 'created By Name', dataField: 'createdByName', width: '15%' }, 
      { text: 'fcaOwnerName', dataField: 'fcaOwnerName', width: '15%',filtertype: 'range',   cellsformat: 'dd-MMM-yyyy'  }, 
  
];

tableListJQ(tableList){
  this.source= {
  localdata:tableList,
  datafields: [
    {  name: 'branchId', type: 'string' }, 
    {  name: 'branchName', type: 'string' }, 
    {  name: 'caseDays', type: 'int' }, 
    {  name: 'caseOrder', type: 'int' }, 
    {  name: 'caseStatus', type: 'string' }, 
    {  name: 'causeOfFailure', type: 'string' }, 
    {  name: 'closureDate', type: 'string' }, 
    {  name: 'companyEmail', type:  'string' }, 
    {  name: 'companyId', type:  'string' }, 
    {  name: 'companyName', type:  'string' }, 
    {  name: 'companyPhone', type:  'string' }, 
    {  name: 'componentGroupId', type:  'string' }, 
    {  name: 'componentGroupName', type:  'string' }, 
    {  name: 'concernDate', type:  'string' }, 
    {  name: 'concernId', type:  'string' }, 
    {  name: 'concernNo', type: 'int' },
    {  name: 'concernTypeId', type: 'string' }, 
    {  name: 'concernTypeName', type: 'string' },
    {  name: 'correctiveAction', type: 'string' }, 
    {  name: 'createdBy', type: 'string' },
    {  name: 'createdByName', type: 'string' }, 
    {  name: 'description', type: 'string' },
    {  name: 'escalated', type: 'boolen' }, 
    {  name: 'escalation', type: 'string' },
    {  name: 'failureCodeId', type: 'string' }, 
    {  name: 'failureCodeName', type: 'string' },
    {  name: 'fcaOwner', type: 'string' }, 
    {  name: 'fcaOwnerName', type: 'string' },
    {  name: 'firstCreator', type: 'string' },
    {  name: 'isFCAUpdate', type: 'boolen' },
    {  name: 'isGDUpdate', type: 'boolen' },
    {  name: 'isValid', type: 'boolen' },
    {  name: 'reopen', type: 'boolen' },
    {  name: 'reopenDate', type: 'string' },
    {  name: 'reportDate', type: 'string' },
    {  name: 'severity', type: 'string' },
    {  name: 'systemGroupId', type: 'string' },
    {  name: 'systemGroupName', type: 'string' },
    {  name: 'timeSpent', type: 'string' },
    {  name: 'userEmployeePosition', type: 'string' },
    {  name: 'visability', type: 'string' },
  
  ],
  
  dataType: 'json',
  };
     this.dataAdapter=  new jqx.dataAdapter(this.source)
}



}
