import { Component,  OnInit, ViewChild, TemplateRef, EventEmitter, Output, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { first } from 'rxjs/operators';
import { BaseService } from '@shared/index';

@Component({
  selector: 'app-select-user-popup',
  templateUrl: './select-user-popup.component.html',
  styleUrls: ['./select-user-popup.component.scss']
})
export class SelectUserPopupComponent implements OnInit {
  formModalRef: BsModalRef | null;
  @ViewChild('selectUserModal') formModal: TemplateRef<any>;

  @Input('inputUser')
  inputUser: string;
  
  @Input('disabled')
  disabled: boolean;

  @Output('userId')
  userId: EventEmitter<any> = new EventEmitter<any>();

  users=[];
  activeStyleUserTable="";
  selectedUser:any;
  saveSelectUserButton=false;
  @ViewChild(MatSort ) sortUser: MatSort;

  searchValueUser:string;
userData={ userName:"", userId:""};
dataSourceUser=new MatTableDataSource(this.users);
displayedColumnsUser: string[] = [  'firstName', 'lastName','company','email'];
  applyFilterEmplyee(filterValue: string) {
    this.dataSourceUser.filter = filterValue.trim().toLowerCase();
  }
  constructor(private _modalService: BsModalService, private _BaseService: BaseService) { }
  openedModel=false;
  ngOnDestroy(){
    if(this.openedModel){
      this.formModalRef.hide();
    }
   }
  ngOnInit() {
    this.loadLists();
  }
  
  loadLists(){
    
    this._BaseService.get("user").pipe(first()).subscribe(result => {
      this.users = result.data;

      this.dataSourceUser = new MatTableDataSource(this.users);
      this.dataSourceUser.sort = this.sortUser;
  
      this.users.forEach(element => {
        if(element.userId==this.inputUser){
          this.selectedUser=element;
          this.saveSelectUser();
        }
      });
     
    
    });
  }

  saveSelectUser(){
    
    this.userData.userId=this.selectedUser.userId
    this.userData.userName=this.selectedUser.firstName + " " + this.selectedUser.lastName;
    this.userId.emit(this.userData);

    this.closeSelectUserModal();
  }
  selectUser(data){
    this.activeStyleUserTable=data.userId;
    this.selectedUser=data;
    this.saveSelectUserButton=true;

      }
closeSelectUserModal(){
  this.activeStyleUserTable='';
  this.saveSelectUserButton=false;
        this.formModalRef.hide();
        this.openedModel=false;
        
        }
openUserSelectModal(){
  this.openedModel=true;
          const configs = {
            keyboard: false,
              ignoreBackdropClick: true,
              class: ' modal-lg modal-primary masterFileModal animated fadeInRight',
              animated:false    }
          this.formModalRef = this._modalService.show(this.formModal, configs);
        }
        ngOnChanges(changes) {
          
         if(this.inputUser){
          
          this.users.forEach(element => {
             if(element.userId==this.inputUser){
               this.selectedUser=element;
               this.saveSelectUser();
             }
           });
          
         }
        }
}
