import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Result, Company } from '../_models';
import { environment } from '../../../environments/environment';
import { SecurityService } from '.';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    private canonicalName: string;
    constructor(private http: HttpClient, private securityService: SecurityService) {
        if (localStorage.getItem('canonicalName') !== null) {
            this.canonicalName = localStorage.getItem('canonicalName') + '/';
        } else {
            this.canonicalName = '';
        }
    }
    // createAuthorizationHeader(headers: HttpHeaders, token: string): HttpHeaders{
    //     headers = headers.append("Accept", "text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8");
    //     //headers = headers.append('X-Tableau-Auth', token);
    //     //headers = headers.append('Cookie', ['workgroup_session_id=' + token + ';'])
    //     return headers;
    // }
    // GetView(token: string, viewLink: string) {
    //     let headers = new HttpHeaders();
    //     headers = this.createAuthorizationHeader(headers, token);
    //     var options =  {
    //         headers: headers
    //     };
    //     return this.http.get<Result>(`${environment.reportUrl}6YCarParcRetailvsFleet?iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no&:size=780,1&:embed=y&:showVizHome=n&:bootstrapWhenNotified=y&:tabs=n&:apiID=host0`);

    // }

    CreateLink(workbookLink: string, viewLink: string, ticket: string) {
        //?iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no&:size=780,1&:embed=y&:showVizHome=n&:bootstrapWhenNotified=y&:tabs=n&:apiID=host0
        if (ticket == null || ticket == '-1') return `${environment.reportUrl}views/${workbookLink}/${viewLink}`;
        else return `${environment.reportUrl}trusted/${ticket}/views/${workbookLink}/${viewLink}`;
    }
    CreateSiteLink(workbookLink: string, viewLink: string, ticket: string, opt?: string) {
        //?iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no&:size=780,1&:embed=y&:showVizHome=n&:bootstrapWhenNotified=y&:tabs=n&:apiID=host0
        if (ticket == null || ticket == '-1')
            return `${environment.reportUrl}/site/ASASDemo/views/${workbookLink}/${viewLink}`;
        else return `${environment.reportUrl}trusted/${ticket}/t/ASASDemo/views/${workbookLink}/${viewLink}` + opt;
    }
    // sendMe(email,pass){
    //     if(email=='')email='00'
    //     if (pass=='')pass='00'
    //     return this.http.get(`${environment.appUrl}${this.canonicalName}Report/saveMe/${email}/${pass}`);

    // }

    CreateUrlLink(url: string, ticket: string, opt?: string) {
        let userId = this.securityService.securityObject.userId ?? '';
        if (!opt) opt = '';
        //?iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no&:size=780,1&:embed=y&:showVizHome=n&:bootstrapWhenNotified=y&:tabs=n&:apiID=host0
        if (ticket == null || ticket == '-1') return url;
        else {
            let lst = url.split(`#/`);
            var subURL = url.split(`/`);
            if (subURL[subURL.length - 1].includes(`?`)) {
                opt = opt.replace(`?`, `&`);
                opt = opt + '&ASASUser=' + userId?.toUpperCase();
            } else {
                if (opt.includes(`?`)) opt = opt + '&ASASUser=' + userId?.toUpperCase();
                else opt = '?ASASUser=' + userId?.toUpperCase();
            }
            return lst[0] + `trusted/${ticket}/` + lst[1] + opt; //+ '?iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no&:embed=y&:showVizHome=n&:bootstrapWhenNotified=y&:tabs=n&:apiID=host0';
        }
        //return `${environment.reportUrl}trusted/${ticket}/views/${workbookLink}/${viewLink}`;
    }

    getTicket() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Report/generate`);
    }
    getSiteTicket() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Report/generateSiteToken`);
    }
    getReports() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Report/report-list`);
    }

    getViews() {
        return this.http.get(`${environment.appUrl}${this.canonicalName}Report/GetViews`);
    }
    getViewPdf(viewId) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/pdf' });
        return this.http.get(`${environment.appUrl}${this.canonicalName}Report/GetViewPdf/` + viewId, {
            headers,
            responseType: 'blob'
        });
    }
    getViewImage(viewId) {
        const headers = new HttpHeaders({ 'Content-Type': 'image/jpeg' });
        return this.http.get(`${environment.appUrl}${this.canonicalName}Report/GetViewImage/` + viewId, {
            headers,
            responseType: 'blob'
        });
    }
    getHtmlFromReport(workbookLink: string, viewLink: string, ticket: string) {
        let url;
        if (ticket == null || ticket == '-1')
            url = `${environment.reportUrl}views/${workbookLink}/${viewLink}?:embed=y#3`;
        else url = `${environment.reportUrl}trusted/${ticket}/views/${workbookLink}/${viewLink}`;
        return this.http.get<string>(url);
    }
}

@Injectable()
export class GeneralConfigurationService {
    private canonicalName: string;
    constructor(private http: HttpClient) {
        if (localStorage.getItem('canonicalName') !== null) {
            this.canonicalName = localStorage.getItem('canonicalName') + '/';
        } else {
            this.canonicalName = '';
        }
    }

    getAll() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}GeneralConfigurations`);
    }

    getById(id: string) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}GeneralConfigurations/` + id);
    }

    add(generalConfiguration) {
        return this.http.post<Result>(
            `${environment.appUrl}${this.canonicalName}GeneralConfigurations/add`,
            generalConfiguration
        );
    }

    update(generalConfiguration) {
        return this.http.put<Result>(
            `${environment.appUrl}${this.canonicalName}GeneralConfigurations/` +
                generalConfiguration.generalConfigurationId,
            generalConfiguration
        );
    }

    delete(url: any, id: string, filenames: any) {
        if (id == undefined) id = null;
        return this.http.post<Result>(
            `${environment.appUrl}${this.canonicalName}Attachment/delete/${url}/${id}/`,
            filenames
        );
    }
    getList(url: any, id: string) {
        if (id == undefined) id = null;
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Attachment/GetList/${url}/${id}/`);
    }

    //attachment

    uploadAttachment(fileToUpload: File, url: string, id?: string, remarks?: string) {
        if (id == undefined) id = null;
        var formData = new FormData();
        const headers = new HttpHeaders({ name: 'Accept', value: 'application/json' });
        formData.append('file', fileToUpload, fileToUpload.name); //formData.append('caseId', id);
        return this.http.post<Result>(
            `${environment.appUrl}${this.canonicalName}Attachment/UploadAttachment/${url}/${id}?remarks=${remarks}`,
            formData,
            { headers: headers }
        );
    }

    downloadFile(url: any, id: string, file: any) {
        if (id == undefined) id = null;
        let str = `${environment.appUrl}${this.canonicalName}Attachment/download/${url}/${id}/${file}`;
        return str;
    }

    ////////////

    search(filterValue: string, dataList: string[] = []): any {
        //search for any word or part of word separated by coma or space
        //hem03/03/2020
        var newDataList: string[] = [];
        /*         if(filterValue=="" ){
                    //dataList=dataList
                    return
                 }
                 var definedColumns = Object.keys(dataList[0])
                 for(var i=0;i<definedColumns.length;i++){
                     var element=definedColumns[i]
                    newDataList=dataList.filter(a=>a[element] && a[element].toLowerCase().indexOf(filterValue.toLowerCase())>-1   )
                    if(newDataList.length>0 ){
                       return newDataList
        
                    }
        
                 } */

        if (filterValue == '') {
            newDataList = dataList;
            return;
        }

        var nameArr = filterValue.toLowerCase().split(',');
        if (filterValue.indexOf(' ') > -1) nameArr = filterValue.toLowerCase().split(' ');

        //this.allOptionsList=this.subOptionsList.filter(a=>a.fca_code.toLowerCase().indexOf(filterValue.toLowerCase())>-1 ||a.optionName.toLowerCase().indexOf(filterValue.toLowerCase())>-1   )
        ///this.allOptionsList=this.subOptionsList.filter(a=>a.fca_code.toLowerCase().indexOf(nameArr)>-1 ||a.optionName.toLowerCase().indexOf(nameArr)>-1   )
        // newDataList=dataList.filter(a=>nameArr.includes(a.substring(0,1).toLowerCase()  ) ||nameArr.includes(a.substring(0,2).toLowerCase()  ) ||nameArr.includes(a.substring(0,3).toLowerCase()  ) ||nameArr.includes(a.substring(0).toLowerCase()  ))
        var definedColumns = Object.keys(dataList[0]);
        for (var i = 0; i < definedColumns.length; i++) {
            var element = definedColumns[i];
            // newDataList=dataList.filter(a=>a[element] && nameArr.includes(a[element].substring(0,1).toLowerCase()  ) ||nameArr.includes(a[element].substring(0,2).toLowerCase()  ) ||nameArr.includes(a[element].substring(0,3).toLowerCase()  ) ||nameArr.includes(a[element].substring(0).toLowerCase()  ))
            newDataList = dataList.filter((a) => a[element] && nameArr.includes(a[element].toLowerCase()));
            if (newDataList.length > 0) {
                return newDataList;
            }
        }
        //newDataList=dataList
        return newDataList;
    }
}

@Injectable()
export class PresetManagerService {
    private canonicalName: string;
    constructor(private http: HttpClient) {
        if (localStorage.getItem('canonicalName') !== null) {
            this.canonicalName = localStorage.getItem('canonicalName') + '/';
        } else {
            this.canonicalName = '';
        }
    }
    linkCreator(link: string, ...param) {
        let res: string = link;

        for (let index = 0; index < param.length; index++) {
            const element = param[index];
            res += element + '/';
        }

        return res;
    }

    getRowsFromTable(presetId: string, fileName: string, sheetHeaderId: string, pageNumber: number) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/get-rows/`,
                presetId,
                fileName,
                sheetHeaderId,
                pageNumber
            )
        );
    }

    getSheet(presetId: string, fileName: string, sheetHeaderId: string, pageNumber: number) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/get-sheet/`,
                presetId,
                fileName,
                sheetHeaderId,
                pageNumber
            )
        );
    }

    setCustom(sheetCustomValue) {
        return this.http.post<Result>(
            `${environment.appUrl}${this.canonicalName}PresetManager/set-custom`,
            sheetCustomValue
        );
    }

    getNotValidateNullTableRows(presetId: string, fileName: string, sheetHeaderId: string, pageNumber: number) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/get-not-validate-null-table-rows/`,
                presetId,
                fileName,
                sheetHeaderId,
                pageNumber
            )
        );
    }

    getNotValidatePrimaryKeyTableRows(presetId: string, fileName: string, sheetHeaderId: string, pageNumber: number) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/get-not-validate-primary-key-table-rows/`,
                presetId,
                fileName,
                sheetHeaderId,
                pageNumber
            )
        );
    }

    getNotValidateUniqueTableRows(
        presetId: string,
        fileName: string,
        sheetHeaderId: string,
        uniqueGroup: string,
        pageNumber: number
    ) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/get-not-validate-unique-table-rows/`,
                presetId,
                fileName,
                sheetHeaderId,
                uniqueGroup,
                pageNumber
            )
        );
    }
    getNotValidateDataTypeTableRows(presetId: string, fileName: string, sheetHeaderId: string, pageNumber: number) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/get-not-validate-data-type-table-rows/`,
                presetId,
                fileName,
                sheetHeaderId,
                pageNumber
            )
        );
    }
    getNotValidateForeignKeysTableRows(presetId: string, fileName: string, sheetHeaderId: string, pageNumber: number) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/get-not-validate-foreign-keys-table-rows/`,
                presetId,
                fileName,
                sheetHeaderId,
                pageNumber
            )
        );
    }
    getNotValidateForeignKeysEntitiesTableRows(
        presetId: string,
        fileName: string,
        sheetHeaderId: string,
        pageNumber: number
    ) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/get-not-validate-foreign-keys-Entities-table-rows/`,
                presetId,
                fileName,
                sheetHeaderId,
                pageNumber
            )
        );
    }
    getNotValidatePrimaryKeysEntitiesTableRows(
        presetId: string,
        fileName: string,
        sheetHeaderId: string,
        pageNumber: number
    ) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/get-not-validate-primary-keys-Entities-table-rows/`,
                presetId,
                fileName,
                sheetHeaderId,
                pageNumber
            )
        );
    }
    getNotValidateDataLengthTableRows(presetId: string, fileName: string, sheetHeaderId: string, pageNumber: number) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/get-not-validate-data-length-table-rows/`,
                presetId,
                fileName,
                sheetHeaderId,
                pageNumber
            )
        );
    }

    getNotValidateDataSizeTableRows(presetId: string, fileName: string, sheetHeaderId: string, pageNumber: number) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/get-not-validate-data-size-table-rows/`,
                presetId,
                fileName,
                sheetHeaderId,
                pageNumber
            )
        );
    }
    getNotValidateDataCompareTableRows(presetId: string, fileName: string, sheetHeaderId: string, pageNumber: number) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/get-not-validate-data-Compare-table-rows/`,
                presetId,
                fileName,
                sheetHeaderId,
                pageNumber
            )
        );
    }

    ReplaceWithRandomNullNotValid(presetId: string, tableName: string, columnName: string, sheetHeaderId: string) {
        return this.http.put<Result>(
            `${environment.appUrl}${this.canonicalName}PresetManager/replace-with-random-null-not-valid/` +
                presetId +
                '/' +
                sheetHeaderId +
                '/' +
                columnName,
            { sheetHeaderId }
        );
    }
    DeleteNullNotValid(presetId: string, tableName: string, columnName: string, sheetHeaderId: string) {
        return this.http.put<Result>(
            `${environment.appUrl}${this.canonicalName}PresetManager/delete-null-not-valid/` +
                presetId +
                '/' +
                sheetHeaderId +
                '/' +
                columnName,
            { sheetHeaderId }
        );
    }
    saveSheet(presetId: string, fileName: string, sheetHeaderId: string, companyId: string, HasExcludedData?: any) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/save-sheet/`,
                presetId,
                fileName,
                sheetHeaderId,
                companyId
            ),
            { params: { HasExcludedData: HasExcludedData } }
        );
    }
    isSheetSavedToDatabase(presetId: string, fileName: string, sheetHeaderId: string) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/is-sheet-saved-to-database/`,
                presetId,
                fileName,
                sheetHeaderId
            )
        );
    }

    updateRow(presetId: string, fileName: string, sheetHeaderId: string, data: any) {
        return this.http.put<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/update-row/`,
                presetId,
                fileName,
                sheetHeaderId
            ),
            data
        );
    }

    exclude(presetId: string, fileName: string, sheetHeaderId: string) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/exclude/`,
                presetId,
                fileName,
                sheetHeaderId
            )
        );
    }

    exportExcel(presetId: string, fileName: string, sheetHeaderId: string) {
        return window.open(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/export-excel-sheet/`,
                presetId,
                fileName,
                sheetHeaderId
            )
        );
    }

    exportExcelError(presetId: string, fileName: string, sheetHeaderId: string) {
        return window.open(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/export-excel-sheet-with-error/`,
                presetId,
                fileName,
                sheetHeaderId
            )
        );
    }

    addRow(presetId: string, fileName: string, sheetHeaderId: string, data: any) {
        return this.http.put<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}PresetManager/add-row/`,
                presetId,
                fileName,
                sheetHeaderId
            ),
            data
        );
    }

    calcData(preset, sheetHeaderId: string, fileName: string, companyId: string) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}presetmanager/calc-data-sheet/`,
                preset.presetId,
                sheetHeaderId,
                fileName,
                companyId
            )
        );
    }
    calcDataSheet(presetid: string, sheetHeaderId: string, fileName: string) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}presetmanager/calc-data-sheet/`,
                presetid,
                sheetHeaderId,
                fileName
            )
        );
    }

    IsWorkbookInserted(preset, fileName: string) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}presetmanager/is-workbook-inserted/`,
                preset.presetId,
                fileName
            )
        );
    }
    IsSheetInserted(preset, sheetHeaderId: string, fileName: string) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}presetmanager/is-sheet-inserted/`,
                preset.presetId,
                sheetHeaderId,
                fileName
            )
        );
    }
    ImportEmpIsSheetInserted(preset, sheetHeaderId: string, fileName: string) {
        return this.http.get<Result>(
            this.linkCreator(
                `${environment.appUrl}${this.canonicalName}presetmanager/ImportEmp-is-sheet-inserted/`,
                preset.presetId,
                sheetHeaderId,
                fileName
            )
        );
    }

    getAllIdAndName() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Presets/get-names-ids`);
    }

    getById(id: string) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Presets/` + id);
    }

    add(preset) {
        return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}Presets/add`, preset);
    }

    delete(id: string) {
        return this.http.delete<Result>(`${environment.appUrl}${this.canonicalName}Presets/` + id);
    }
}

@Injectable()
export class PresetService {
    private canonicalName: string;
    constructor(private http: HttpClient) {
        if (localStorage.getItem('canonicalName') !== null) {
            this.canonicalName = localStorage.getItem('canonicalName') + '/';
        } else {
            this.canonicalName = '';
        }
    }

    getAll(isForSftpBrige = null) {
        var qParamTerm = isForSftpBrige === true ? '?isSftpBrige=true' : '?isSftpBrige=false';
        if (isForSftpBrige === null) qParamTerm = '';

        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Presets${qParamTerm}`);
    }
    getSheetDetailsbyPresetId(presetid) {
        return this.http.get<Result>(
            `${environment.appUrl}${this.canonicalName}Sheets/Get-Sheet-Details-By-PresetID/` + presetid
        );
    }
    getcolumnList() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Presets/get-table`);
    }

    getAllPresets() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Presets/Get-All`);
    }
    getAllSftpProfileList() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}SftpProfile`);
    }
    CheckPresetCode(code) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Presets/check-code/` + code);
    }
    getTable(name: String) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Presets/get-table/` + name);
    }
    getAllForRole() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Presets/get-sec`);
    }
    getAllIdAndName() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Presets/get-names-ids`);
    }

    getById(id: string) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Presets/` + id);
    }

    getMappings() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}DataMapping/All-Mappings`);
    }

    add(preset) {
        return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}Presets/add`, preset);
    }

    update(preset) {
        return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}Presets/` + preset.presetId, preset);
    }

    delete(id: string) {
        return this.http.delete<Result>(`${environment.appUrl}${this.canonicalName}Presets/` + id);
    }
}

@Injectable()
export class SheetService {
    private canonicalName: string;
    constructor(private http: HttpClient) {
        if (localStorage.getItem('canonicalName') !== null) {
            this.canonicalName = localStorage.getItem('canonicalName') + '/';
        } else {
            this.canonicalName = '';
        }
    }

    getAll() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Sheets`);
    }

    getSheetHeadersByPresetId(PresetId: string) {
        return this.http.get<Result>(
            `${environment.appUrl}${this.canonicalName}Sheets/Get-Sheet-Headers-By-PresetID/` + PresetId
        );
    }

    getSheetdetailssByHeaderId(HeaderID: string) {
        return this.http.get<Result>(
            `${environment.appUrl}${this.canonicalName}Sheets/Get-Sheet-Details-By-HeaderID/` + HeaderID
        );
    }

    getById(id: string) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Sheets/` + id);
    }

    add(sheet) {
        return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}Sheets/add`, sheet);
    }

    update(sheet) {
        return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}Sheets/` + sheet.sheetHeaderId, sheet);
    }

    delete(id: string) {
        return this.http.delete<Result>(`${environment.appUrl}${this.canonicalName}Sheets/` + id);
    }
}
