export const environment = {
    production: false,
    //appUrl: 'http://localhost:8050/api/v1/',               // local 
    appUrl: 'https://apitest.asasdrive.com/api/v1/', // dev 
    //appUrl: 'https://api.asasdrive.com/api/v1/',           // live
    
    cmsAttachmentUrl: 'https://apitest.asasdrive.com/',
    //cmsAttachmentUrl:'aaaaadev.api.sharewinds.com/',
    environmentName: 'dev',

    //reportUrl: 'https://asasconnect.com/',
    reportUrl: 'https://asasparts.com/',
    timeoutMinutes: 60
};
