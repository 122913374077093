import { Component,OnDestroy, OnInit} from '@angular/core';
import swal from 'sweetalert2';
import { BaseService } from '@shared/index';
import { first } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-message-system',
  templateUrl: './message-system.component.html',
  styleUrls: ['./message-system.component.scss']
})
export class MessageSystemComponent implements OnInit,OnDestroy {
action;
  dangerAlert = false;
  alerts: any[] = [{
    type: '',
    msg: ``,
    timeout: 1
  }];
  subscription: Subscription = new Subscription();

  addAlert(typeData , msgData): void {
    this.dangerAlert = false;
    this.alerts=[];

    let time = 3000;
    if (typeData === 'danger') {
      this.dangerAlert = true;
      time = 99999999999;
    }
    if (this.alerts.length === 0) {
      this.alerts.push({
      type: typeData,
      msg: msgData,
      timeout: time
    });
    } else {
      this.alerts.splice(0, 1);
       this.alerts.push({
        type: typeData,
        msg: msgData,
      timeout: time
    });
    }

  }
  errorMsg(data) {

      swal.fire({
      allowOutsideClick: false,
      allowEscapeKey : false,
      title: data.messageTitle,
      text: data.messageText,
      type: data.icon.toLowerCase(),
      showCancelButton: data.buttonTwoTitle ? true : false,
      confirmButtonColor: '#FF8989',
      cancelButtonColor: '#BCBCCB',
      // iconColor:  '#FF8989',
      cancelButtonText: data.buttonTwoTitle,
      confirmButtonText: data.buttonOneTitle
    }).then((result) => {
      if (result.value) {
      if (data.buttonOneAction) {
          this._BaseService.action(this.action);

        }

       } else {
       if (data.buttonTwoAction) {
      this._BaseService.action(this.action);

        }
       } });
  }
  onClosedAlert(dismissedAlert): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
    this.alerts = [];
  this.dangerAlert = false;
  }
  errorMsgLocation(result) {
    this.alerts = [];
    this._BaseService.changeMessage({id: null, action : null});

    if (result.data.location === 'Pop Up') {
      this.errorMsg(result.data);
      } else if (result.data.location === 'Top green') {
        this.addAlert('success', result.data.messageText);

      } else if (result.data.location === 'Top red') {
        this.addAlert('danger', result.data.messageText);

      } else {
        this.addAlert('danger', 'Failed to Save Changes');
      }
   }
  constructor(private _BaseService: BaseService) { }

  ngOnInit() {
    this.alerts = [];

    this.subscription.add( this._BaseService.currentMessage.subscribe(message => this.getMssg(message)));

  }

  getMssg(messageData) {
    if (!messageData.id){
      return;
    }
    this.action = messageData.action;
    this.alerts = [];
    this._BaseService.get('Message/'+ messageData.id).pipe(first()).subscribe(result => this.errorMsgLocation(result));
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();

}

}
