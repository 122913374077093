import {NgModule} from '@angular/core';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';



import { jqxBarGaugeComponent } from './jqWidgetFiles';
import { jqxBulletChartComponent } from './jqWidgetFiles';
import { jqxButtonGroupComponent } from './jqWidgetFiles';
import { jqxButtonComponent } from './jqWidgetFiles';
import { jqxChartComponent } from './jqWidgetFiles';
import { jqxCheckBoxComponent } from './jqWidgetFiles';
import { jqxColorPickerComponent } from './jqWidgetFiles';
import { jqxComboBoxComponent } from './jqWidgetFiles';




import { jqxComplexInputComponent } from './jqWidgetFiles';
import { jqxDataTableComponent } from './jqWidgetFiles';
import { jqxDateTimeInputComponent } from './jqWidgetFiles';
import { jqxDockingLayoutComponent } from './jqWidgetFiles';
import { jqxDockPanelComponent } from './jqWidgetFiles';
import { jqxDragDropComponent } from './jqWidgetFiles';
import { jqxDrawComponent } from './jqWidgetFiles';
import { jqxDropDownButtonComponent } from './jqWidgetFiles';

import { jqxDropDownListComponent } from './jqWidgetFiles';
import { jqxEditorComponent } from './jqWidgetFiles';
import { jqxExpanderComponent } from './jqWidgetFiles';
import { jqxFileUploadComponent } from './jqWidgetFiles';
import { jqxFormComponent } from './jqWidgetFiles';
import { jqxFormattedInputComponent } from './jqWidgetFiles';
import { jqxGaugeComponent } from './jqWidgetFiles';
import { jqxHeatMapComponent } from './jqWidgetFiles';
import { jqxInputComponent } from './jqWidgetFiles';

import { jqxKanbanComponent } from './jqWidgetFiles';
import { jqxKnobComponent } from './jqWidgetFiles';
import { jqxLayoutComponent } from './jqWidgetFiles';
import { jqxLinearGaugeComponent } from './jqWidgetFiles';
import { jqxLinkButtonComponent } from './jqWidgetFiles';
import { jqxListBoxComponent } from './jqWidgetFiles';
import { jqxListMenuComponent } from './jqWidgetFiles';
import { jqxLoaderComponent } from './jqWidgetFiles';
import { jqxMaskedInputComponent } from './jqWidgetFiles';
import { jqxMenuComponent } from './jqWidgetFiles';
import { jqxNavBarComponent } from './jqWidgetFiles';
import { jqxNavigationBarComponent } from './jqWidgetFiles';
import { jqxNotificationComponent } from './jqWidgetFiles';
import { jqxNumberInputComponent } from './jqWidgetFiles';
import { jqxPasswordInputComponent } from './jqWidgetFiles';
import { jqxPivotDesignerComponent } from './jqWidgetFiles';

import { jqxPivotGridComponent } from './jqWidgetFiles';
import { jqxPopoverComponent } from './jqWidgetFiles';
import { jqxProgressBarComponent } from './jqWidgetFiles';
import { jqxRadioButtonComponent } from './jqWidgetFiles';
import { jqxRangeSelectorComponent } from './jqWidgetFiles';
import { jqxRatingComponent } from './jqWidgetFiles';
import { jqxRepeatButtonComponent } from './jqWidgetFiles';
import { jqxResponsivePanelComponent } from './jqWidgetFiles';
import { jqxRibbonComponent } from './jqWidgetFiles';
import { jqxSchedulerComponent } from './jqWidgetFiles';
import { jqxScrollBarComponent } from './jqWidgetFiles';
import { jqxScrollViewComponent } from './jqWidgetFiles';
import { jqxSliderComponent } from './jqWidgetFiles';
import { jqxSortableComponent } from './jqWidgetFiles';
import { jqxSplitterComponent } from './jqWidgetFiles';
import { jqxSwitchButtonComponent } from './jqWidgetFiles';
import { jqxTagCloudComponent } from './jqWidgetFiles';
import { jqxTextAreaComponent } from './jqWidgetFiles';
import { jqxTimePickerComponent } from './jqWidgetFiles';
import { jqxToggleButtonComponent } from './jqWidgetFiles';
import { jqxToolBarComponent } from './jqWidgetFiles';
import { jqxTooltipComponent } from './jqWidgetFiles';
import { jqxTreeComponent } from './jqWidgetFiles';
import { jqxTreeMapComponent } from './jqWidgetFiles';
import { jqxValidatorComponent } from './jqWidgetFiles';
import { jqxWindowComponent } from './jqWidgetFiles';


import { jqxGridComponent } from './jqWidgetFiles';
import { jqxCalendarComponent } from './jqWidgetFiles';
import { jqxDockingComponent } from './jqWidgetFiles';
import { jqxPanelComponent } from './jqWidgetFiles';
import { jqxTabsComponent } from './jqWidgetFiles';
import { FormsModule } from '@angular/forms';

import { jqxTreeGridComponent } from './jqWidgetFiles';
// import {SharedModule} from '@shared/shared.module';
@NgModule({
    declarations: [
        jqxGridComponent,
        jqxCalendarComponent,
        jqxDockingComponent,
          jqxPanelComponent,
    jqxPivotGridComponent,jqxPopoverComponent,jqxProgressBarComponent,jqxRadioButtonComponent,jqxRangeSelectorComponent,jqxRatingComponent,jqxRepeatButtonComponent,jqxResponsivePanelComponent,
    jqxRibbonComponent,jqxSchedulerComponent,jqxScrollBarComponent,jqxScrollViewComponent,jqxSliderComponent,jqxSortableComponent,jqxSplitterComponent,jqxSwitchButtonComponent,jqxTagCloudComponent,
    jqxBarGaugeComponent,jqxBulletChartComponent,jqxButtonGroupComponent,jqxButtonComponent, jqxChartComponent,jqxCheckBoxComponent,jqxColorPickerComponent,jqxComboBoxComponent,
    jqxComplexInputComponent,jqxDataTableComponent,jqxDateTimeInputComponent,jqxDockingLayoutComponent,jqxDockPanelComponent,jqxDragDropComponent, jqxDrawComponent,jqxDropDownButtonComponent,
    jqxDropDownListComponent,jqxEditorComponent,jqxExpanderComponent,jqxFileUploadComponent,jqxFormComponent,jqxFormattedInputComponent,jqxGaugeComponent,jqxHeatMapComponent,jqxInputComponent,
    jqxKanbanComponent,jqxKnobComponent,jqxLayoutComponent,jqxLinearGaugeComponent,jqxLinkButtonComponent,jqxListBoxComponent,jqxListMenuComponent,jqxPasswordInputComponent,
    jqxLoaderComponent,jqxMaskedInputComponent,jqxMenuComponent,jqxNavBarComponent,jqxNavigationBarComponent,jqxNotificationComponent,jqxNumberInputComponent,jqxPivotDesignerComponent,
    jqxTextAreaComponent,jqxTimePickerComponent,jqxToggleButtonComponent,jqxToolBarComponent,jqxTooltipComponent,jqxTreeComponent,jqxTreeGridComponent,
    jqxTreeMapComponent,jqxValidatorComponent,jqxWindowComponent,
        jqxTabsComponent,
      ],
      imports:[FormsModule ],
  exports: [
    jqxGridComponent,
    jqxCalendarComponent,
    jqxDockingComponent,
      jqxPanelComponent,
jqxPivotGridComponent,jqxPopoverComponent,jqxProgressBarComponent,jqxRadioButtonComponent,jqxRangeSelectorComponent,jqxRatingComponent,jqxRepeatButtonComponent,jqxResponsivePanelComponent,
jqxRibbonComponent,jqxSchedulerComponent,jqxScrollBarComponent,jqxScrollViewComponent,jqxSliderComponent,jqxSortableComponent,jqxSplitterComponent,jqxSwitchButtonComponent,jqxTagCloudComponent,
jqxBarGaugeComponent,jqxBulletChartComponent,jqxButtonGroupComponent,jqxButtonComponent, jqxChartComponent,jqxCheckBoxComponent,jqxColorPickerComponent,jqxComboBoxComponent,
jqxComplexInputComponent,jqxDataTableComponent,jqxDateTimeInputComponent,jqxDockingLayoutComponent,jqxDockPanelComponent,jqxDragDropComponent, jqxDrawComponent,jqxDropDownButtonComponent,
jqxDropDownListComponent,jqxEditorComponent,jqxExpanderComponent,jqxFileUploadComponent,jqxFormComponent,jqxFormattedInputComponent,jqxGaugeComponent,jqxHeatMapComponent,jqxInputComponent,
jqxKanbanComponent,jqxKnobComponent,jqxLayoutComponent,jqxLinearGaugeComponent,jqxLinkButtonComponent,jqxListBoxComponent,jqxListMenuComponent,jqxPasswordInputComponent,
jqxLoaderComponent,jqxMaskedInputComponent,jqxMenuComponent,jqxNavBarComponent,jqxNavigationBarComponent,jqxNotificationComponent,jqxNumberInputComponent,jqxPivotDesignerComponent,
jqxTextAreaComponent,jqxTimePickerComponent,jqxToggleButtonComponent,jqxToolBarComponent,jqxTooltipComponent,jqxTreeComponent,jqxTreeGridComponent,
jqxTreeMapComponent,jqxValidatorComponent,jqxWindowComponent,
    jqxTabsComponent,
  ]
})
export class JQWidgetModule {}


