import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { environment } from '../environments/environment';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { LoaderService } from '@shared/services/loader.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    environmentName = '';
    environmentUrl = '';
    userActivity;
    userInactive: Subject<any> = new Subject();

    constructor(
        routeActivate: ActivatedRoute,
        private router: Router,
        private idle: Idle,
        private keepalive: Keepalive,
        private loaderService: LoaderService
    ) {
        let returnUrl = window.location.href;
        if (returnUrl.indexOf('code=') > 0) {
            this.environmentName = environment.environmentName;
            this.environmentUrl = environment.appUrl;
            let startIndex = returnUrl.indexOf('code=') + 5;
            let endIndex = returnUrl.indexOf('&');
            let code = returnUrl.substr(startIndex, endIndex - startIndex);
            localStorage.setItem('gmail_code', code);
            let gmail_source = localStorage.getItem('gmail_source');
        }

        idle.setIdle(environment.timeoutMinutes * 60);

        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleStart.subscribe(() => this.router.navigate(['login']));

        this.reset();

        //this.setTimeout();
        //this.userInactive.subscribe(() => this.router.navigate(['login']));
    }

    isLoading: Subject<boolean> = this.loaderService.isLoading;

    reset() {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    }

    // setTimeout() {
    //   this.userActivity = setTimeout(() => this.userInactive.next(undefined), environment.timeoutMinutes * 60 * 1000);
    // }

    // @HostListener('window:mousemove') refreshUserState() {
    //   clearTimeout(this.userActivity);
    //   this.setTimeout();
    // }

    ngOnInit() {
        // let returnUrl=window.location.href;
        // if(returnUrl.indexOf('code')>0){
        //   let startIndex=returnUrl.indexOf('code')+5
        //   let endIndex=returnUrl.indexOf('&')
        //   let urlEndIndex=returnUrl.indexOf('?')
        //   let code=returnUrl.substr(startIndex,endIndex-startIndex)
        //   let url=returnUrl.substr(0,urlEndIndex-1)
        //   let body={code:code,url:url}
        //   localStorage.setItem('oauthToken', code);

        //     this.cmsService.saveCode(body).subscribe(result => {

        //        if(result)this.router.navigate['cms-inbox']
        //      })
        //     }

        //   this.route.queryParamMap.subscribe(queryParams => {
        //  if(queryParams){
        //     //post code
        //     var code= queryParams.get("code");
        //     this.cmsService.saveCode(code).subscribe(result => {
        //          if(result)this.router.navigate['cms-inbox']
        //        })
        //       }
        //  })

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}
