import { Validators } from '@angular/forms';
import { TableProperties } from './../_models/table-properties';
import { Filter } from './../_models/filter';
import { FieldType } from './../_models/form-item';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import * as XLSX from 'xlsx';
export class Utils {

  static overflow() {
    let elements = document.getElementsByClassName('container-fluid');
    if (!elements)
      return;
    for (var i = 0; i < elements.length; i++) {
      elements.item(i).classList.remove('overflow-y');
      elements.item(i).classList.add('overflow-y');
    }
  }

  static noOverflow() {
    let elements = document.getElementsByClassName('container-fluid');
    if (!elements)
      return;
    for (var i = 0; i < elements.length; i++) {
      elements.item(i).classList.remove('overflow-y');
    }
  }

  static async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then(() =>{});
  }
  static LowerCaseFirstChar(data: string) {
    return this.setCharAt(data, 0, data.charAt(0).toLowerCase());
  }

  static setCharAt(str, index, chr) {
    if (index > str.length - 1) return str;
    return str.substr(0, index) + chr + str.substr(index + 1);
  }

  static toCamelCase(str: string) {
    return str
      .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
      .replace(/\s/g, '')
      .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
  }

  static toJsNames(str: string) {
    return this.LowerCaseFirstChar(this.toCamelCase(str))
  }

  static distinct(arr: any[], idPropertyName: string = '') {
    let flags: any[] = [];
    let output: any[] = [];

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] == null)
        continue;
      var sss = flags.indexOf(arr[i]);
      if (idPropertyName != '') {
        if (output.findIndex(x => x[idPropertyName] == arr[i][idPropertyName]) == -1) {
          output.push(arr[i]);
        }
      } else {
        if (output.indexOf(arr[i]) == -1) {
          output.push(arr[i]);
        }
      }

    }

    return output;
  }

  // createMessage(notifications, title: string, content: string, type: string) {
  //   return;
  // }
  // errorMessage(notifications, title: string, content: string, type: string) {
  //   return;
  // }

  static createMessage(notifications: NotificationsService, title: string, content: string, type: string) {

    if (type == null && content == null)
      return;

    if (type == null || 'error'.toLowerCase() === type.toLowerCase())
      notifications.create(title, content, NotificationType.Error);
    else if ('success'.toLowerCase() === type.toLowerCase())
      notifications.create(title, content, NotificationType.Success);
    else if ('info'.toLowerCase() === type.toLowerCase())
      notifications.create(title, content, NotificationType.Info);
    else if ('warn'.toLowerCase() === type.toLowerCase())
      notifications.create(title, content, NotificationType.Warn);
    else if ('Alert'.toLowerCase() === type.toLowerCase())
      notifications.create(title, content, NotificationType.Alert);
    else if ('Bare'.toLowerCase() === type.toLowerCase())
      notifications.create(title, content, NotificationType.Bare);
  }
  static errorMessage(notifications: NotificationsService, title: string, content: string, type: string) {

    if (type == null && content == null)
      return;

    if (type == null || 'error'.toLowerCase() === type.toLowerCase())
      notifications.create(title, content, NotificationType.Error);
    else if ('success'.toLowerCase() === type.toLowerCase())
      notifications.create(title, content, NotificationType.Success);
    else if ('info'.toLowerCase() === type.toLowerCase())
      notifications.create(title, content, NotificationType.Info);
    else if ('warn'.toLowerCase() === type.toLowerCase())
      notifications.create(title, content, NotificationType.Warn);
    else if ('Alert'.toLowerCase() === type.toLowerCase())
      notifications.create(title, content, NotificationType.Alert);
    else if ('Bare'.toLowerCase() === type.toLowerCase())
      notifications.create(title, content, NotificationType.Bare);
  }
  static getSheet(workbook: XLSX.WorkBook, sheetName: string): XLSX.WorkSheet {
    return workbook.Sheets[sheetName];
  }
  static readRows(sheet: XLSX.WorkSheet): any[] {
    return XLSX.utils.sheet_to_json(sheet);
  }

  static getRowsFromWorkbook(workbook: XLSX.WorkBook, sheetName: string): any[] {
    return this.readRows(this.getSheet(workbook, sheetName));
  }

  static getSheetsName(workbook: XLSX.WorkBook): string[] {
    return workbook.SheetNames;
  }

  static getRowsFromFristSheet(workbook: XLSX.WorkBook): any[] {
    const sheetsName = this.getSheetsName(workbook);
    if (sheetsName.length <= 0)
      return null;
    return this.getRowsFromWorkbook(workbook, sheetsName[0]);
  }

  static readExcelFile(bstr: string): XLSX.WorkBook {
    return XLSX.read(bstr, { type: "binary" });
  }


  static getValue(obj: any, prop?: string) {
    let res = null;
    prop = (prop + "").toLowerCase();
    for (var p in obj) {
      if (obj.hasOwnProperty(p) && (!prop ||  prop == (p + "").toLowerCase())) {
        res = obj[p];
        break;
      }
    }
    return res;
  }

  static getType(obj: any, prop?: string) {
    let res = null;
    prop = (prop + "").toLowerCase();
    for (var p in obj) {
      if (obj.hasOwnProperty(p) && (!prop || prop == (p + "").toLowerCase())) {
        res = typeof obj[p];
        break;
      }
    }
    return res;
  }

  static isDatetimeType(obj: any, prop?: string){
    // return Object.prototype.toString.call(obj[prop]) === '[object Date]';
    return obj[prop] instanceof Date;
  }

  static setHoursToAllDateTimeInObject(obj: any, hours: number){
    for (var p in obj) {
      if (!obj.hasOwnProperty(p)) 
        continue;
      if(!Utils.isDatetimeType(obj, p))
        continue;

      let res = obj[p] as Date;
      res.setHours(hours);  
      obj[p] = res;          
    }
  }

  static deleteList(listDeleteFrom: any[], listToDelete: any[]) {
    let res = listDeleteFrom;
    for (let index = 0; index < listToDelete.length; index++) {
      const element = listToDelete[index];
      let indexIn = res.indexOf(element);
      if (indexIn > -1) {
        res.splice(indexIn, 1);
      }
    }

    return res;
  }

  static deleteListByProp(listDeleteFrom: any[], listToDelete: any[], propNameToCompare: string[]) {
    let res = [];
    for (let index = 0; index < listDeleteFrom.length; index++) {
      const element = listDeleteFrom[index];
      if(!element)
        continue;
      
      let indexIn = listToDelete.findIndex(x => 
        (!propNameToCompare[0] || x[propNameToCompare[0]] == element[propNameToCompare[0]]) &&
        (!propNameToCompare[1] || x[propNameToCompare[1]] == element[propNameToCompare[1]]) && 
        (!propNameToCompare[2] || x[propNameToCompare[2]] == element[propNameToCompare[2]]) 
      );
      if (indexIn > -1) {
        continue;
      }
      
      res.push(listDeleteFrom[index]);
    }

    return res;
  }

  static pick(o, ...props) {
    return Object.assign({}, ...props.map(prop => ({ [prop]: o[prop] })));
  }

  static pickFromArray(o, ...props) {
    let res = [];

    for (let index = 0; index < o.length; index++) {
      const element = o[index];
      res.push(this.pick(element, ...props));

    }

    return res;
  }

  static linkCreator(link: string, ...param) {
    let res: string = link;

    for (let index = 0; index < param.length; index++) {
      const element = param[index];
      res += element + '/';
    }

    return res;
  }
  // static fromUifilterToObjectFilter(uiFilters: Filter[]){
  //     let objFilter:any;
  //     for (let index = 0; index < uiFilters.length; index++) {
  //         const finalFilter = uiFilters[index];
  //         if(finalFilter.prop && objFilter.hasOwnProperty(finalFilter.prop))
  //             objFilter[finalFilter.prop] = finalFilter.value;            
  //     } 

  //     return objFilter;
  // }


  static isValidateRequired(needValidation: boolean) {
    return needValidation ? "required" : null;
  }

  static checkIsValid(itemId, needValidation, tabName: string) {

    if (!this.isValidateRequired(needValidation))
      return true;
    const element: HTMLElement = document.getElementById(itemId + tabName);

    if (!element)
      return true;

    const value = (<HTMLInputElement>document.getElementById(itemId + tabName)).value;    

    if (value == 'null' || value == null || value=='')
      return false;

    const classes = element.className;
    if (!classes)
      return true;

    return true;

    const classIndexValid = classes.indexOf('ng-valid');
    const classIndexInvalid = classes.indexOf('ng-invalid');
    const classIndexDirty = classes.indexOf('ng-dirty');
    const classIndexUntouched = classes.indexOf('ng-untouched');
    const classIndexTouched = classes.indexOf('ng-touched');

    // if (classIndexUntouched > -1)
    //   return null;

    // if (classIndexTouched > -1 && classIndexValid > -1)
    //   return true;

    
  }

  static checkValidation(tableProperties: TableProperties, tabName: string) {

    const columnsNeedToValidat = tableProperties.columns.filter(x => x.validator && x.validator.indexOf(Validators.required) > -1);

    if (!columnsNeedToValidat)
      return true;

    for (let index = 0; index < columnsNeedToValidat.length; index++) {
      const element = columnsNeedToValidat[index];
      if (element.needValidation) {
        element.isValid = this.checkIsValid(element.prop, element.needValidation, tabName);        
        const tag = (<HTMLInputElement>document.getElementById(element.prop + tabName));
        const tagError = (<HTMLInputElement>document.getElementById(element.prop + "errorMsg"));
        if(!tag)
          continue;
        if(element.isValid == false){   
          if(tagError){
            tagError.classList.remove('showErrorMsg');

          }   
          tag.classList.add('is-invalid');
        }else{
          tag.classList.remove('is-invalid');
          if(tagError){
            tagError.classList.add('showErrorMsg');

          }  
        }
      }

    }

    if(tableProperties.columns.filter(x => x.isValid == false).length > 0) {
      return false;
    }
      
    return true;
  }


  static getArrayNotInArray(fullList: any[], filterList: any[], propNameToCompare: string)
  : any[] {
    let res: any[]=[];
    for (let index = 0; index < fullList.length; index++) {
      const element = fullList[index];
      if(filterList.findIndex(obj => obj[propNameToCompare] == element[propNameToCompare]) > -1){
        continue;
      }
        
      res.push(element);      
    }

    return res;
  }

  static getArrayNotInArrayByProps(fullList: any[], propNameFullListToCompare: string, filterList: any[], propNameToCompareFilterList: string)
  : any[] {
    let res: any[]=[];
    for (let index = 0; index < fullList.length; index++) {
      const element = fullList[index];
      if(filterList.findIndex(obj => obj[propNameToCompareFilterList] == element[propNameFullListToCompare]) > -1){
        continue;
      }
        
      res.push(element);      
    }

    return res;
  }



  
}





  //DATE FUNCTIONS
declare global {
  interface Date {
     addDays(days: number, useThis?: boolean): Date;
     isToday(): boolean;
     clone(): Date;
     isAnotherMonth(date: Date): boolean;
     isWeekend(): boolean;
     isSameDate(date: Date): boolean;
     getStringDate(): String;
  }
}

Date.prototype.addDays = function (days: number): Date {
  if (!days) return this;
  let date = this;
  date.setDate(date.getDate() + days);

  return date;
};

Date.prototype.isToday = function (): boolean{
  let today = new Date();
  return this.isSameDate(today);
};

Date.prototype.clone = function (): Date{
  return new Date(+this);
};

Date.prototype.isAnotherMonth = function (date: Date): boolean {
  return date && this.getMonth() !== date.getMonth();
};

Date.prototype.isWeekend = function (): boolean  {
  return this.getDay() === 0 || this.getDay() === 6;
};

Date.prototype.isSameDate = function (date: Date): boolean  {
  return date && this.getFullYear() === date.getFullYear() && this.getMonth() === date.getMonth() && this.getDate() === date.getDate();
};

Date.prototype.getStringDate = function (): String {
   //Month names in Brazilian Portuguese
   let monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
   //Month names in English
   //let monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
   let today = new Date();
   if (this.getMonth() == today.getMonth() && this.getDay() == today.getDay()) {
       return "Hoje";
       //return "Today";
   } else if (this.getMonth() == today.getMonth() && this.getDay() == today.getDay() + 1) {
       return "Amanhã";
       //return "Tomorrow";
   } else if (this.getMonth() == today.getMonth() && this.getDay() == today.getDay() - 1) {
       return "Ontem";
       //return "Yesterday";
   } else {
       return this.getDay() + ' de ' + this.monthNames[this.getMonth()] + ' de ' + this.getFullYear();
       //return this.monthNames[this.getMonth()] + ' ' + this.getDay() + ', ' +  this.getFullYear();
   }
};