import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Result, TaskType } from '../_models';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    private canonicalName: string;
    private messageSource = new BehaviorSubject({});
    currentMessage = this.messageSource.asObservable();
    private actionFn = new BehaviorSubject('');
    currentAction = this.actionFn.asObservable();

    @Output() menuCollapsOrExpand: EventEmitter<any> = new EventEmitter<any>();
    @Output() onWidgetOrderChanged: EventEmitter<any> = new EventEmitter<any>();

    /* add below any Shared Api Urls That are Reapeated too much
     but please consider CamelCase Typing style */
    public readonly sharedApiUrls = {
        getAllUsers: 'user'
    };

    constructor(private http: HttpClient, private router: Router) {
        if (localStorage.getItem('canonicalName') !== null) {
            this.canonicalName = localStorage.getItem('canonicalName') + '/';
        } else {
            this.canonicalName = '';
        }
    }
    client_id = '54836345725-njionuiopqcgej6a9nj000r86u7fisp6.apps.googleusercontent.com';

    getCode(url?) {
        localStorage.removeItem('gmail_source');
        let fullurl = window.location.href;
        let baseUrl = fullurl.substr(0, fullurl.indexOf(fullurl.split('/')[2])) + fullurl.split('/')[2];
        if (!baseUrl.endsWith('/')) baseUrl += '/';
        let navUrl = this.router.url;
        localStorage.setItem('gmail_source', navUrl);
        ///baseUrl='urn:ietf:wg:oauth:2.0:oob'//
        let endPoint = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${this.client_id}&response_type=code&scope=https://mail.google.com/%20email&access_type=offline&prompt=consent&redirect_uri=${baseUrl}`;

        window.location.href = endPoint;
    }
    //   Get
    get(url) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}${url}`);
    }

    //   Get a response of type any
    getAny(url) {
        return this.http.get<any>(`${environment.appUrl}${this.canonicalName}${url}`);
    }
    getBlob(url) {
        return this.http.get(`${environment.appUrl}${this.canonicalName}${url}`, { responseType: 'blob' });
    }
    postBlob(url, body) {
        return this.http.post(`${environment.appUrl}${this.canonicalName}${url}`, body, { responseType: 'blob' });
    }

    //   Get
    getIntegration(url) {
        return this.http.get<any>(`${environment.appUrl}${this.canonicalName}${url}`);
    }

    //   Post

    post(url, body) {
        return this.http.post<any>(`${environment.appUrl}${this.canonicalName}${url}`, body);
    }
    //   Put
    put(url, body) {
        return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}${url}`, body);
    }

    //   Download
    downloadFile(file) {
        const str = `${environment.appUrl}${this.canonicalName}${file}`;
        return str;
    }
    downloadFilePsa(file) {
        const str = `${environment.cmsAttachmentUrl}${this.canonicalName}${file}`;
        return str;
    }

    downloadFilebyid(url: any, id: string, file: any) {
        if (id == undefined) id = null;
        let str = `${environment.appUrl}${this.canonicalName}Attachment/download/${url}/${id}/${file}`;
        return str;
    }
    //   Delete

    delete(url) {
        return this.http.delete<Result>(`${environment.appUrl}${this.canonicalName}${url}`);
    }

    deletebyid(url: any, id: string, filenames: any) {
        if (id == undefined) id = null;
        return this.http.post<Result>(
            `${environment.appUrl}${this.canonicalName}Attachment/delete/${url}/${id}/`,
            filenames
        );
    }

    changeStatus(url, status, body) {
        return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}${url}` + status, body);
    }

    uploadAttachment(fileToUpload: File, url: string, id?: string) {
        if (id === undefined) {
            id = null;
        }
        const formData = new FormData();
        const headers = new HttpHeaders({ name: 'Accept', value: 'application/json' });
        formData.append('file', fileToUpload, fileToUpload.name); // formData.append('caseId', id);
        return this.http.post<Result>(
            `${environment.appUrl}${this.canonicalName}Attachment/UploadAttachment/${url}/${id}`,
            formData,
            { headers: headers }
        );
    }

    downloadFiles(url: any, id: string, file: any) {
        if (id === undefined) {
            id = null;
        }
        const str = `${environment.appUrl}${this.canonicalName}Attachment/download/${url}/${id}/${file}`;
        return str;
    }

    uploadimage(fileToUpload: File) {
        var formData = new FormData();
        const headers = new HttpHeaders({ name: 'Accept', value: 'application/json' });
        formData.append('file', fileToUpload, fileToUpload.name); //formData.append('caseId', id);
        // let options = [{ 'Content-Type': 'multipart/form-data' }];
        return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}permission/Upload`, formData, {
            headers: headers
        });
    }

    getDealers() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}pricelist/get-dealers-combo`);
    }

    changeMessage(mssgData) {
        this.messageSource.next(mssgData);
    }

    action(actionFn) {
        this.actionFn.next(actionFn);
    }

    getLookUp(key?: string) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}LookUp/getValues/${key}`);
    }
}
