import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import { SecurityService } from '../services';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private location: Location,
        private securityService: SecurityService,
        private router: Router) {
    }
    urls: any[]
    inUrl = false
    twoFactor = false
    previousUrl;
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        this.previousUrl = this.location.path();
        this.securityService.securityObject = JSON.parse(sessionStorage.getItem('securityObject'));
        // let tfactor = localStorage.getItem('twoFactor');
     
        let claimType: string = route.data["claimType"];
        // if(this.securityService.securityObject.TwoFactorAuthRequired && tfactor=='1'){
        //     this.twoFactor= true;
        // }

        if (this.securityService.securityObject &&
            this.securityService.securityObject.isAuthenticated &&
            this.securityService.hasClaim(claimType)
            && !this.twoFactor
           
            
        ) {
            return true;

        }
        else {
            this.router.navigate(['login']);
            return false;
        }
    }
}