import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivityFilter, Result, TaskType } from '../_models';
import { environment } from '../../../environments/environment';

@Injectable()
export class FCRService {
    private canonicalName : string;
    constructor(private http: HttpClient) { 
        if (localStorage.getItem("canonicalName") !== null) {
            this.canonicalName = localStorage.getItem('canonicalName') + '/';
        }else{
            this.canonicalName = "";
        }
    }
    //   ConcernType
    getAllConcernTypeList(pageNumber,pageSize) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRConcernType/get-All/${pageNumber}/${pageSize}`);
    }
    getAllConcernType(type) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRConcernType/get-All`+type);
    }
    addConcernType(ConcernType) {
        return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRConcernType/add`, ConcernType);
    }
    updateConcernType(ConcernType) {
        return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}FCRConcernType/edit`, ConcernType);
    }

    deleteConcernType(ConcernTypeList) {
        return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRConcernType/delete-List`, ConcernTypeList);
    }
    changeConcernTypeStatus(ConcernTypeList,status) {
        return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRConcernType/change-Status/`+status, ConcernTypeList);
    }


    //  FCRCompomemtGroup


    getAllFCRCompomemtGroup() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRCompomemtGroup/get-All`);
    }
    getFCRCompomemtGroupList(pageNumber,pageSize) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRCompomemtGroup/get-All/${pageNumber}/${pageSize}`);
    }
    addFCRCompomemtGroup(FCRCompomemtGroup) {
        return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRCompomemtGroup/add`, FCRCompomemtGroup);
    }
    updateFCRCompomemtGroup(FCRCompomemtGroup) {
        return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}FCRCompomemtGroup/edit`, FCRCompomemtGroup);
    }

    deleteFCRCompomemtGroup(FCRCompomemtGroupList) {
        return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRCompomemtGroup/delete-List`, FCRCompomemtGroupList);
    }
    changeFCRCompomemtGroupStatus(FCRCompomemtGroupList,status) {
        return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRCompomemtGroup/change-Status/`+status, FCRCompomemtGroupList);
    }




//  FCRSystemGroup


getAllFCRSystemGroupList(pageNumber,pageSize) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRSystemGroup/get-All/${pageNumber}/${pageSize}`);
}

getAllFCRSystemGroup() {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRSystemGroup/get-All`);
}
getMakes() {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRLookup/getmakes`);
}
getModelsbyMakeid(makeid) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRLookup/getmodelsbymake/${makeid}`);
}

addFCRSystemGroup(FCRSystemGroup) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRSystemGroup/add`, FCRSystemGroup);
}
updateFCRSystemGroup(FCRSystemGroup) {
    return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}FCRSystemGroup/edit`, FCRSystemGroup);
}

deleteFCRSystemGroup(FCRSystemGroupList) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRSystemGroup/delete-List`, FCRSystemGroupList);
}
changeFCRSystemGroupStatus(FCRSystemGroupList,status) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRSystemGroup/change-Status/`+status, FCRSystemGroupList);
}
downloadFile(file){
    let str=`${environment.appUrl}${this.canonicalName}${file}`
    return str;
  
  
  }

getSystemGroupByComponantId(ComponantId) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRSystemGroup/get-By-Component-Group-Id/`+ComponantId);
}

getFailuerBySystemGroupId(componentgroupId) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRFailureCode/get-By-Component-Group-Id/`+componentgroupId);
}


//  FCRFailureCode

getAllFCRFailureCode() {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRFailureCode/get-All/`);
}
getAllFCRFailureCodeDDL() {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRFailureCode/drop-dowen-list`);
}
getFCRFailureCodeList(pageNunmber,pageSize) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRFailureCode/get-All/${pageNunmber}/${pageSize}`);
}
addFCRFailureCode(FCRFailureCode) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRFailureCode/add`, FCRFailureCode);
}
updateFCRFailureCode(FCRFailureCode) {
    return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}FCRFailureCode/edit`, FCRFailureCode);
}

deleteFCRFailureCode(FCRFailureCodeList) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRFailureCode/delete-List`, FCRFailureCodeList);
}
changeFCRFailureCodeStatus(FCRFailureCodeList,status) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRFailureCode/change-Status/`+status, FCRFailureCodeList);
}



getSystemByCompomemt(id) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRSystemGroup/get-By-Component-Group-Id/` + id);
}



// FCR LIST
changeFCRStatus(taskList,status) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}BPTaskType/change-status/`+status, taskList);
}
deletFCRList(FCRList) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRConcern/delete-List/`, FCRList);
}
searchFCR(fcr) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRConcern/Search`, fcr);
}

// FCR Export
ExportCases(body?:any ) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRConcern/excel-cases`,body);
  }

  ExportVins(body?:any ) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRConcernVins/excel-vinlist`,body);
  }

  ExportCommunications(body?:any ) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRCommunication/excel-Communicationlist`,body);
  }

  ExportCagris(body?:any ) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRCagris/excel-Cagrislist`,body);
  }

  ExportShipping(body?:any ) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRShipping/excel-Shippinglist`,body);
  }

// list for DDL Search Page

getFCRListDistributer() {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}company/get-list-combo`);
}

getFCRListDistributerIsDealer() {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}company/get-list-combo-IsDealer`);
}



getFCRListStatus() {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRLookup/Get-Lookup-List` + "Case Status");
}
changeFCRCaseStatus(id,status) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRConcern/change-CaseStatus/${id}/${status}`,{} );
}
getFCRListLookup(data) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRLookup/Get-Lookup-List/` +data);
}

getAllHistory(vin?:any,report?:any ) {
    return this.http.get<any>(`${environment.appUrl}${this.canonicalName}Report/GetVinHistroy/${vin}/${report}`);
  }
  
getvinList(ConcernId) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRConcernVins/GetByConcernId/${ConcernId}`);
}

getMakeAndModel(data) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}VINDecoding/` +data);
}

getMakeAndModelGryMarket(data) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRVINDecoding/GetByVin/` +data);
}


getVinModelSeries(data) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}ModelSeries/get-Series-ByModel-Text/` +data);
}
getAllConcern() {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRConcern/get-All`);
}

getFCRListCreatedUsers() {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}User/GetUsersIsDelars`);
}
getFCRListOwnerUsers() {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}User/GetUsersIsFca`);
}


// FCR add edit getById

getFCRConcernById(id) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRConcern/get-ById/` + id);
}
getConcernId() {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRConcern/max/` );
}
addFCRConcern(FCRConcern) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRConcern/add`, FCRConcern);
}

updateFCRConcern(FCRConcern) {
    return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}FCRConcern/edit` , FCRConcern);
}

log(concernLog){
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}ConcernLog`, concernLog);
}

//  vin Tab
deletAttachmentListVin(AttachmentList) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRAttachement/delete-Vin-List`, AttachmentList);
}
deletAttachmentListCagris(AttachmentList) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRAttachement/delete-Cagris-List`, AttachmentList);
}
addAttachmentVin(attachmentData) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRConcernVins/add-Attachemen`, attachmentData);
}

addAttachmenitCagris(attachmentData) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRCagris/add-Attachemen`, attachmentData);
}

getAllFCRVinsByConcernId(ConcernId) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRConcernVins/GetByConcernId/`+ConcernId );
}
getAllFCRVinsByVinId(ConcernId,VinId) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRConcernVins/get-ById/${ConcernId}/${VinId}` );
}

getAllFCRConcernVins() {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRConcernVins/get-All` );
}

addVin(vinData) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRConcernVins/add`, vinData);
}

updateVin(vinData) {
    return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}FCRConcernVins/edit`, vinData);
}

// Vin Parts
deletVinPartList(vinPart) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRVinParts/delete-List`, vinPart);
}

getAllVinParts(partKey) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Part/get-Part-LikeById/`+partKey );
}
addVinPart(PartsData) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRVinParts/add`, PartsData);
}
updateVinPart(PartsData) {
    return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}FCRVinParts/edit`, PartsData);
}
// comunecation tab

deletAttachmentList(AttachmentList) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRAttachement/delete-List`, AttachmentList);
}




     
deletCommunicationList(List) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRCommunication/delete-List`, List);
}
deletCagrisList(List) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRCagris/delete-List`, List);
}
deletShippingList(List) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRShipping/delete-List`, List);
}



deletVinList(List) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRConcernVins/delete-List`, List);
}
getCommunicationAttachmentByConIdAndTime(ConcernId,time) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRCommunication/get-ById/`+ConcernId+'/'+time );
}
getAllFCRCommByConcernId(ConcernId) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRCommunication/GetByConcernId/`+ConcernId );
}
addAttachmentComm(attachmentData) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRCommunication/add-Attachemen`, attachmentData);
}
addComm(commData) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRCommunication/add`, commData);
}

updateComm(commData) {
    return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}FCRCommunication/edit`, commData);
}


// Cagris tab

getAllFCRCagrisByCagrisId(ticketid) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRCagris/get-ById/`+ticketid );
}

getAllFCRCagrisByConcernId(ConcernId) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRCagris/GetByConcernId/`+ConcernId );
}
addCagris(commData) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRCagris/add`, commData);
}

updateCagris(commData) {
    return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}FCRCagris/edit`, commData);
}


//Shipping Tab
getAttachmentAttachmentByConIdAndbillNo(ConcernId,billNo) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRShipping/get-ById/`+ConcernId+'/'+billNo );
}
deletAttachmentListShipping(AttachmentList) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRAttachement/delete-Shipping-List`, AttachmentList);
}
addAttachmentShipping(attachmentData) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRShipping/add-Attachemen`, attachmentData);
}
// FCRShipping/GetByConcernId/{concernId}

// FCRShipping/get-ById/{airwayBillNo}

// FCRShipping/add

// FCRShipping/edit




getAllFCRShippingByairwayBillNo(airwayBillNo) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRShipping/get-ById/`+airwayBillNo );
}

getAllFCRShippingByConcernId(ConcernId) {
    return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}FCRShipping/GetByConcernId/`+ConcernId );
}

addShipping(Shippingata) {
    return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}FCRShipping/add`, Shippingata);
}

updateShipping(Shippingata) {
    return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}FCRShipping/edit`, Shippingata);
}






    changeTaskMFStatus(taskList,status) {
        return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}BPTaskType/change-status/`+status, taskList);
    }
    getAll() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}BPTaskType`);
    }

    getListForCombo() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}BPTaskType/get-list-combo`);
    }

    getById(id: string) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}BPTaskType/` + id);
    }

    add(taskType: TaskType) {
        return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}BPTaskType/add`, taskType);
    }

    update(taskType: TaskType) {
        return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}BPTaskType/` + taskType.taskTypeId, taskType);
    }

    delete(id: string) {
        return this.http.delete<Result>(`${environment.appUrl}${this.canonicalName}BPTaskType/` + id);
    }

    bpChangeActivityStatus(actList,status) {
        return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}bpactivity/change-status/`+status, actList);
    }

    bpSearchActivity(filter: ActivityFilter, key: string) {
        return this.http.post<Result>(`${environment.appUrl}${this.canonicalName}bpactivity/Search/` + key, filter);
    }

    bpGetActivityById(id: string) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}bpactivity/` + id);
    }

    bpApproveActivity(id: string) {
        return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}bpactivity/approve/` + id, null);
    }

    bpRejectActivity(id: string) {
        return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}bpactivity/reject/` + id, null);
    }

    bpCancelActivity(id: string) {
        return this.http.put<Result>(`${environment.appUrl}${this.canonicalName}bpactivity/cancel/` + id, null);
    }

    vinDecode(vin: string) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}VINDecoding/` + vin);
    }

    usersGetAll() {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}user`);
    }

    branchGetByCompanyId(companyId: string) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}branch/company/` + companyId);
    }
}
