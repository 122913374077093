 import { Component, OnInit, ViewEncapsulation,OnDestroy, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
  import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
  import { BsModalService } from 'ngx-bootstrap/modal';
  import swal from 'sweetalert2';
  import { first } from 'rxjs/operators';
  import { User, ModelSeriesOption } from '../../_models';
  import {SelectionModel} from '@angular/cdk/collections';
  import {MatTableDataSource} from '@angular/material/table';
  import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
  import {MatSort} from '@angular/material/sort';
  import { HttpErrorResponse } from '@angular/common/http';
  import { Router, ActivatedRoute } from '@angular/router';
  import { emit } from 'cluster';
  import { url } from 'inspector';
import { BaseService, ErrorService } from '@shared/services';
  
  @Component({
    selector: 'app-attachment-udn',
    templateUrl: './attachment-udn.component.html',
    styleUrls: ['./attachment-udn.component.scss']
  })
  export class AttachmentUDNComponent implements OnInit {
  
    attachmentList=[{
         "name":'',
      "file":'',
      "attachementtype":''
   
    }]
    @Input('canAdd')canAdd: boolean;
    @Input() childId:any;
    @Input() urlpath:any;
    @Input() attachmentFilesList
    @Output('allAttachmentList')
    allAttachmentList: EventEmitter<any> = new EventEmitter<any>();
  
    @Output('newAttachment')
    newAttachment: EventEmitter<any> = new EventEmitter<any>();
  
    @Output('deletAttachment')
      deletAttachment: EventEmitter<any> = new EventEmitter<any>();
    constructor(private  _baseService:BaseService, private fb: FormBuilder,private _errorService: ErrorService,private _modalService: BsModalService,) { }
  
    displayedColumns: string[] = [ 'select',  'file','descrption'];
    dataSource = new MatTableDataSource<any>(this.attachmentList);
    selection = new SelectionModel<any>(true, []);
  
    @ViewChild(MatSort) sortAttachment: MatSort;
    formAddAttachmentsModalRef: BsModalRef;
    @ViewChild('formAddAttachmentsModal') formAddAttachmentsModal: TemplateRef<any>;
    configs =  {
      keyboard: false,
        ignoreBackdropClick: true,
        class: ' modal-lg modal-primary masterFileModal  animated fadeInRight',
        animated:false    }
  customClasses = {
      sortAscending: 'datatable-icon-down',
      sortDescending: 'datatable-icon-up'
  };
  
    openAddAttachmentsModal() {
      this.formAddAttachmentsModalRef = this._modalService.show(this.formAddAttachmentsModal, this.configs);
      this.attachmentForm.reset()
  }
  attachmentForm: FormGroup;
  
  private initForm(): void {
      
    this.attachmentForm = this.fb.group({
      'name': new FormControl(null, [Validators.required]),
      'file': new FormControl(null, [Validators.required]),
      'attachementtype': new FormControl(null),
      'threadid':new FormControl(null)
      
     });
  }
  
  closeAddAttachmentsModal() {
    this.laddaSearchProgress=false;
    this.fileToUpload = null;
  this.attFormDescription='';
      this.formAddAttachmentsModalRef.hide();
  }
    ngOnInit() {
      this.initForm();
      this.attachmentList=[]
      this.attachmentList.splice(0,1)
  
          this.dataSource   = new MatTableDataSource(this.attachmentFilesList);
          this.dataSource.sort = this.sortAttachment
          this.attachmentList=this.attachmentFilesList
        
      
    }
    ngOnChanges(changes) {
      if (this.attachmentFilesList.length > 0) {
        this.dataSource   = new MatTableDataSource(this.attachmentFilesList);
        this.dataSource.sort = this.sortAttachment
      }
      
      
      }
      formModalRef: BsModalRef | null;
    @ViewChild('formAddEditModal') formModal: TemplateRef<any>;
    openFormModal() {
      
      const configs = {
        keyboard: false,
          ignoreBackdropClick: true,
          class: ' modal-lg modal-primary masterFileModal  animated fadeInRight',
          animated:false    }
      this.formModalRef = this._modalService.show(this.formModal, configs);
      this.openPopup=true;
  }
  closeAddEditModal() {
    if(this.changeNewContant){
  this.confirmClose();
  
    }else{
      this.closeAddAttachmentsModal();
      this.openPopup=false;
      this.changeNewContant=false;
      this.saveContant=false;
    }
      
  }
          openPopup=false;
          changeNewContant=false;
          saveContant=false;
  confirmClose(){
    this._errorService.getAPMassage('1').pipe(first()).subscribe(result => {      
      var data = result.data;
      swal.fire({
        allowOutsideClick: false,
      allowEscapeKey : false,
      title: data.messageTitle,
      text: data.messageText,
      type: data.icon.toLowerCase(),
      showCancelButton: data.buttonTwoTitle ? true :false,
      confirmButtonColor: '#FF8989',
      cancelButtonColor: '#BCBCCB',
      // iconColor:  '#FF8989',
      cancelButtonText: data.buttonTwoTitle,
      confirmButtonText: data.buttonOneTitle
    
      }).then((result) => {
        if (result.value) {
         
    
          
          // swal(
          //   couponstatus+'!',
          //   'The Coupon has been '+couponstatus+'.',
          //   'success'
          // )
        }else{
          this.closeAddAttachmentsModal();
          this.openPopup=false;
          this.changeNewContant=false;
          this.saveContant=false;
    
        }
      });
        });
        
  
  }
  addNewItem(){
    this.openFormModal();
  }
   
  
    openDialog() {
  
    }
    sortData(taps) {
  
    }
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
    
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataSource.data.forEach(row => this.selection.select(row));
    }
    
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.taskName + 1}`;
    }
    fileToUpload: File = null;
    handleFileInput(files: FileList) {
      this.attachmentForm.controls['name'].setValue(files.item(0).name.split('.')[0])
      this.attachmentForm.controls['attachementtype'].setValue(files.item(0).name.split('.')[1])
      //check size
      files.item(0).size 
      var _size = files[0].size;
      var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
      i=0;while(_size>900){_size/=1024;i++;}
      var exactSize = (Math.round(_size*100)/100)+' '+fSExt[i];
      this.fileSize=exactSize;
      this.fileToUpload=files[0];
      this.changeNewContant=true;
      
  }
  fileSize:any
  
  attFormDescription='';
  errorMsgLocation(result){
    if(result.data.location=='Pop Up'){
      this.errorMsg(result.data);
      }else if(result.data.location=='Top green'){
        this.addAlert('success',result.data.messageText);

      }else if(result.data.location=='Top red'){
        this.addAlert('danger',result.data.messageText);

      }else{
        this.addAlert('danger','Failed to Save Changes');
      }
   }
   errorMsg(data){

    swal.fire({
      allowOutsideClick: false,
      allowEscapeKey : false,
      title: data.messageTitle,
      text: data.messageText,
      type: data.icon.toLowerCase(),
      showCancelButton: data.buttonTwoTitle ? true :false,
      confirmButtonColor: '#FF8989',
      cancelButtonColor: '#BCBCCB',
      // iconColor:  '#FF8989',
      cancelButtonText: data.buttonTwoTitle,
      confirmButtonText: data.buttonOneTitle
    }).then((result) => {
      if (result.value) {




      }else{

      }


    });
  }
  saveAddEdit(){

    
    var Description=this.attFormDescription;
    var file=this.fileToUpload
    if(!file || !file.name){
      this._errorService.getAPMassage('3').pipe(first()).subscribe(result => {
       
    
    
       this.errorMsgLocation(result);
       
          });
          return
    } 
    //this.uploadFileToActivity()
    this.laddaSearchProgress=1;

    this._baseService.uploadAttachment(file,this.urlpath,this.childId).pipe(first()).subscribe(result =>{
      this.newAttachment.emit( {name:result.data,descrption:Description});
  
      this.attachmentList.push({"name":this.attachmentForm.controls['name'].value,"file":result.data,"attachementtype":this.attachmentForm.controls['attachementtype'].value
   })
      this.allAttachmentList.emit( this.attachmentList);
      this.attachmentForm.controls['threadid'].setValue(this.childId)
      this.dataSource   = new MatTableDataSource(this.attachmentList);
      this.dataSource.sort = this.sortAttachment
      this.closeAddAttachmentsModal();
      this.openPopup=false;
      this.changeNewContant=false;
      this.saveContant=false;
      var allFiles=[]
      allFiles.push(file)
      },err=>{}
    )
      
  
  }
  dangerAlert=false
  laddaSearchProgress: boolean | number = false;

  onClosedAlert(dismissedAlert): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
    this.alerts=[];
  this.dangerAlert=false;
  }
  alerts: any[] = [{
    type: '',
    msg: ``,
    timeout: 1
  }];
  addAlert(typeData,msgData): void {
    
    this.dangerAlert=false;

    var time = 3000;
    if(typeData=='danger'){
      this.dangerAlert=true;
      time=99999999999;
    }
    if(this.alerts.length==0){
      this.alerts.push({
      type: typeData,
      msg: msgData,
      timeout: time
    });
    }else{
      this.alerts.splice(0, 1)
       this.alerts.push({
        type: typeData,
        msg: msgData,
      timeout: time
    });
    }
    
  }
  downloadFile(event:any){
   let str= this._baseService.downloadFilebyid(this.urlpath,this.childId, event.file)
      let pwa = window.open(str);
      if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          alert( 'Please disable your Pop-up blocker and try again.');
      } 
  
  }
  deleteAttachments(){ 
    if(this.selection.selected.length==0)  {    
      return;
    }
this.deletAttachment.emit(this.selection.selected)
    ////uncomment below and edit to delete attachment
      //  this.cmsService.deleteAttachment(this.selection.selected).pipe(first()).subscribe(() =>{
      //  })
      let filenames=[]
      ////delete the file from server
      this.selection.selected.forEach(item => {
         let index: number = this.attachmentList.findIndex(d => d === item);
         this.attachmentList.splice(index,1)
         this.dataSource   = new MatTableDataSource(this.attachmentList);
         this.dataSource.sort = this.sortAttachment
        filenames.push(item['file'])
            });
        this._baseService.deletebyid(this.urlpath,this.childId,filenames).pipe(first()).subscribe(() =>{
            }) 
      
            this.selection = new SelectionModel<Element>(true, []);
       //
  
  
  }
  }
  