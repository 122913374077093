import { Component, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, Input } from "@angular/core";

@Component({
    selector: 'custom-search',
    templateUrl: './custom-search.component.html',
    styleUrls: ['./custom-search.component.scss'],
})
export class CustomSearchComponent implements OnInit
{
   
    //drawings:Drawing[] = null;
   
    @Input()  searchValue: string;

    @Output() value: EventEmitter<string> = new EventEmitter<string>();
    @Output() showForm: EventEmitter<boolean> = new EventEmitter<boolean>();

    show:boolean = false;
    constructor(){}

    ngOnInit(): void {
       // this.drawings = drawingList;
    }
    // ngOnChanges(changes: SimpleChanges) {
    //     this.value.emit(this.searchValue);
    // }
    submit(){

    }
    onSearchChange(value: string): void{
        //alert(value);
        this.value.emit(this.searchValue);
    }
    
    showFilter():void{
        if(this.show === false)
            {
                this.show = true;
                this.showForm.emit(this.show);
            }
        else
           {
               this.show =false;
               this.showForm.emit(this.show);
           }
    }
    // changeList(payLoad:Drawing[]):void{
    //     //alert(payLoad[0].drawingId);
    //     this.drawings = payLoad;
    // }
}