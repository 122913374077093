import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.txt';
@Injectable({
    providedIn: 'root'
  })
export class ExportToExcelService {

  constructor() { }
  arrJson=[]
  public exportAsExcelFile(json: any[], excelFileName: string,staticId:string): void {

    let worksheet: XLSX.WorkSheet
   // worksheet = XLSX.utils.sheet_add_json(worksheet,[{'A813':'A813'}]);

   // json.unshift({"A813":""});
    worksheet= XLSX.utils.sheet_add_json(worksheet,json,{origin: "B1"});
    //worksheet["A2"]={"A813":""}
    XLSX.utils.sheet_add_json(worksheet,
      [

      ],
      {
        header: [staticId],
        //skipHeader:true,
        origin: "A1"
      }
    );
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'txt', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName,EXCEL_EXTENSION);
  }


  public exportAsExcelFileMarktingVin(jsonVins: any[], excelFileName: string,leadStatus?: any[],callStatus?: any[]): void {

    let worksheetVins: XLSX.WorkSheet
    let worksheetLead: XLSX.WorkSheet
    let worksheetCall: XLSX.WorkSheet

    worksheetVins= XLSX.utils.sheet_add_json(worksheetVins,jsonVins);//,{origin: "B1"}
    worksheetLead= XLSX.utils.sheet_add_json(worksheetLead,leadStatus);//,{origin: "B1"}
    worksheetCall= XLSX.utils.sheet_add_json(worksheetCall,callStatus);//,{origin: "B1"}

   const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheetVins,"Vin");
      XLSX.utils.book_append_sheet(workbook, worksheetLead,"Lead Status");
     XLSX.utils.book_append_sheet(workbook, worksheetCall,"Call Status");

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName,'xlsx');
  }


  public exportAsExcelFilePublic(json: any[], excelFileName: string): void {

    let worksheet: XLSX.WorkSheet

     worksheet= XLSX.utils.sheet_add_json(worksheet,json);//,{origin: "B1"}
    // XLSX.utils.sheet_add_json(worksheet,
    //   [

    //   ],
    //   {
    //     header: [staticId],
    //     origin: "A1"
    //   }
    // );

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data',] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName,'xlsx');
  }

  public exportAsExcelFileCMS(jsonCases: any[], excelFileName: string,jsonCommunications?: any[],jsonContacts?: any[],jsonFlags?: any[]): void {


    //const contactsData=json['contacts']
    //const flagsData=json['flags']

    let worksheet: XLSX.WorkSheet
    let worksheetComm: XLSX.WorkSheet
    let worksheetContacts: XLSX.WorkSheet
    let worksheetFlags: XLSX.WorkSheet


     worksheet= XLSX.utils.sheet_add_json(worksheet,jsonCases);//,{origin: "B1"}
     worksheetComm= XLSX.utils.sheet_add_json(worksheetComm,jsonCommunications);//,{origin: "B1"}
     worksheetContacts= XLSX.utils.sheet_add_json(worksheetContacts,jsonContacts);//,{origin: "B1"}
     worksheetFlags= XLSX.utils.sheet_add_json(worksheetFlags,jsonFlags);//,{origin: "B1"}
     // XLSX.utils.sheet_add_json(worksheet,
    //   [

    //   ],
    //   {
    //     header: [staticId],
    //     origin: "A1"
    //   }
    // );

   // const workbook: XLSX.WorkBook= {  SheetNames: ['Cases','Communication Threads','Flags','Contacts'] };

   const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet,"Cases");
      XLSX.utils.book_append_sheet(workbook, worksheetComm,"Communications");
     XLSX.utils.book_append_sheet(workbook, worksheetContacts,"Contacts");
       XLSX.utils.book_append_sheet(workbook, worksheetFlags,"Flags");
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName,'xlsx');
  }
  public exportAsExcelFileGDCMS(jsonCases: any[], excelFileName: string,jsonContacts?: any[],jsonFlags?: any[]): void {

    let worksheet: XLSX.WorkSheet
    let worksheetComm: XLSX.WorkSheet
    let worksheetContacts: XLSX.WorkSheet
    let worksheetFlags: XLSX.WorkSheet
     worksheet= XLSX.utils.sheet_add_json(worksheet,jsonCases);//,{origin: "B1"}
     worksheetContacts= XLSX.utils.sheet_add_json(worksheetContacts,jsonContacts);//,{origin: "B1"}
     worksheetFlags= XLSX.utils.sheet_add_json(worksheetFlags,jsonFlags);//,{origin: "B1"}

   const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet,"Cases");
     XLSX.utils.book_append_sheet(workbook, worksheetContacts,"Contacts");
       XLSX.utils.book_append_sheet(workbook, worksheetFlags,"Flags");
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName,'xlsx');
  }
  public exportAsExcelFileEscalationDetarctor(detractors: any[],Actions: any[], excelFileName: string): void {

    let worksheetDetractor: XLSX.WorkSheet
    let worksheetAction: XLSX.WorkSheet



    worksheetDetractor= XLSX.utils.sheet_add_json(worksheetDetractor,detractors);//,{origin: "B1"}
    worksheetAction= XLSX.utils.sheet_add_json(worksheetAction,Actions);//,{origin: "B1"}

   const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheetDetractor,"Concern log");
    XLSX.utils.book_append_sheet(workbook, worksheetAction,"Actions");
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName,'xlsx');
  }

  public exportAsExcelFileEscalation(jsonCases: any[], excelFileName: string): void {

    let worksheet: XLSX.WorkSheet
    let worksheetComm: XLSX.WorkSheet
    let worksheetContacts: XLSX.WorkSheet
    let worksheetFlags: XLSX.WorkSheet


     worksheet= XLSX.utils.sheet_add_json(worksheet,jsonCases);//,{origin: "B1"}

   const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet,"Escalation log");
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName,'xlsx');
  }
  private saveAsExcelFile(buffer: any, fileName: string, ext:string): void {

    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + ext);
  }


 public exportAsExcelFileFCR(jsonCases: any[], excelFileName: string,jsonvins?: any[],jsonCommunications?: any[],jsonCagris?: any[],jsonShipping?: any[]): void {

  let worksheet: XLSX.WorkSheet
  let worksheetVin: XLSX.WorkSheet
  let worksheetComm: XLSX.WorkSheet
  let worksheetCagris: XLSX.WorkSheet
  let worksheetShipping: XLSX.WorkSheet


   worksheet= XLSX.utils.sheet_add_json(worksheet,jsonCases);//,{origin: "B1"}
   worksheetVin= XLSX.utils.sheet_add_json(worksheetVin,jsonvins);//,{origin: "B1"}
   worksheetComm= XLSX.utils.sheet_add_json(worksheetComm,jsonCommunications);//,{origin: "B1"}
   worksheetCagris= XLSX.utils.sheet_add_json(worksheetCagris,jsonCagris);//,{origin: "B1"}
   worksheetShipping= XLSX.utils.sheet_add_json(worksheetShipping,jsonShipping);//,{origin: "B1"}

 const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet,"Cases");
  XLSX.utils.book_append_sheet(workbook, worksheetVin,"Vins");
  XLSX.utils.book_append_sheet(workbook, worksheetComm,"Communications");
  XLSX.utils.book_append_sheet(workbook, worksheetCagris,"Cagris");
  XLSX.utils.book_append_sheet(workbook, worksheetShipping,"Part Status");
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFile(excelBuffer, excelFileName,'xlsx');
}


public exportAsExcelFileMarktingVinData(jsonVins: any[], excelFileName: string,jsonVinsLog: any[]): void {

  let worksheetVins: XLSX.WorkSheet
  let worksheetVinsLog: XLSX.WorkSheet

  worksheetVins= XLSX.utils.sheet_add_json(worksheetVins,jsonVins);//,{origin: "B1"}
  worksheetVinsLog= XLSX.utils.sheet_add_json(worksheetVinsLog,jsonVinsLog);//,{origin: "B1"}

 const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheetVins,"Vins");
  XLSX.utils.book_append_sheet(workbook, worksheetVinsLog,"Status");

  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFile(excelBuffer, excelFileName,'xlsx');
}

public exportAsExcelFileData(jsonDate: any[], excelFileName: string,headerInfo?:any): void {

  let worksheetSession: XLSX.WorkSheet

  worksheetSession= XLSX.utils.json_to_sheet(jsonDate, { header: headerInfo});

 const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheetSession,"excelFileName");

  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFile(excelBuffer, excelFileName,'xlsx');
}


public exportAsExcelFileDataSession(jsonDate: any[], jsonDateDetails: any[], excelFileName: string,headerInfo?:any): void {

    let worksheetSession: XLSX.WorkSheet
    let worksheetSessionDetails: XLSX.WorkSheet

    XLSX.utils.json_to_sheet([{A:1,B:2},{B:2,C:3}], {header:['C']})
    worksheetSession= XLSX.utils.json_to_sheet(jsonDate, { header: headerInfo});
    worksheetSessionDetails = XLSX.utils.json_to_sheet(jsonDateDetails, { header: headerInfo});
   const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheetSession,"Sessions");
    XLSX.utils.book_append_sheet(workbook, worksheetSessionDetails,"Sessions Details");

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName,'xlsx');
  }

}
